'use client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';

import { Product } from '@/shared/types/common';
import { Tabs, TabsItemCard } from '@/shared/ui/tabs';

import styles from './select-pet-properties.module.scss';

type SelectPetPropertiesFeatureProps = {
  products: Product[];
  href: string;
  itemType: string;
  onTabChange: (product: Product) => void;
};
export const sortProperties = (
  products: Product[],
  sorting?: 'asc' | 'desc',
) => {
  const priorityMap = new Map<number, Product[]>();
  const getPriority = (item: Product): number => {
    const hasFlyable = item.flyable ? 1 : 0;
    const hasRideable = item.rideable ? 1 : 0;
    const hasNeon =
      item.pumping === 'neon' || item.pumping === 'mega_neon' ? 1 : 0;

    return hasFlyable + hasRideable + hasNeon; // Priorities will be 3, 2, 1, or 0
  };

  // Categorize products
  products.forEach(item => {
    const priority = getPriority(item);
    if (!priorityMap.has(priority)) {
      priorityMap.set(priority, []);
    }
    priorityMap.get(priority)!.push(item);
  });

  const oneFeatureItems = priorityMap.get(1) || [];
  const zeroFeatureItems = priorityMap.get(0) || [];

  const requiredItem = zeroFeatureItems.find(
    item => item.pumping === 'default' && !item.flyable && !item.rideable,
  );

  if (requiredItem) {
    oneFeatureItems.unshift(requiredItem);
  }

  const sortByParam = (data: Product[]) => {
    return [...data].sort((a, b) => {
      const priceA = parseFloat(String(a.price ?? ''));
      const priceB = parseFloat(String(b.price ?? ''));

      if (sorting === 'asc') {
        return priceA - priceB;
      } else if (sorting === 'desc') {
        return priceB - priceA;
      }

      return priceA - priceB;
    });
  };

  const sorted = sortByParam(products);

  const props = [sorted[0], sorted[1], oneFeatureItems[0]].filter(item =>
    Boolean(item),
  );

  const uniqueArray = Array.from(
    new Map(props.map(item => [item.id, item])).values(),
  );

  return uniqueArray;
};

export const SelectPetPropertiesFeature = observer(
  ({
    products,
    itemType,
    href,
    onTabChange,
  }: SelectPetPropertiesFeatureProps) => {
    const tabs =
      Array?.isArray(products) && products.length > 0 ? products : [];

    const router = useRouter();

    return (
      Array.isArray(tabs) && (
        <Tabs<string | number>
          defaultActiveTab={products[0]?.id}
          onTabsChange={id => {
            onTabChange(products.find(prod => prod.id === id)!);
          }}
        >
          {itemType === 'pet' &&
            tabs.map((tab, idx) => {
              return (
                <TabsItemCard
                  key={`tab.value-${idx}`}
                  className={cn(styles['tab-item'])}
                  value={tab?.id}
                  tagSize={18}
                  flyable={tab?.flyable!}
                  rideable={tab?.rideable!}
                  pumping={tab?.pumping!}
                />
              );
            })}

          {(itemType === 'transport' || itemType === 'egg') && (
            <TabsItemCard
              className={cn(styles['tab-item'])}
              value={'default'}
              onClick={() => router.push(href)}
              tagSize={18}
              pumping={'default'}
            />
          )}
        </Tabs>
      )
    );
  },
);
