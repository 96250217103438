'use client';
/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * API Сервиса Маркет (Adopt Me) #market #adopt_me
 * OpenAPI spec version: 1.0.0
 */
import { ItemsPackResponse } from '@hooks/queries/common.types';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseInfiniteQueryResult,
  DefinedUseQueryResult,
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import {
  FavoriteItemsResponse,
  GlobalItem,
  ProductById,
} from '@/shared/types/common';

import type { BodyType, ErrorType } from '../../axios-client';
import { useCustomClient } from '../../axios-client';
import type {
  GetProductsRealName200,
  GetProductsRealName404,
  PostProducts200,
  PostProducts400,
  PostProductsBody,
  PostProductsPacks200,
  PostProductsPacks400,
  PostProductsPacksBody,
} from '.././models';

/**
 * @summary Получать группу товаров
 */
export const useGetProductsRealNameHook = () => {
  const getProductsRealName = useCustomClient<GetProductsRealName200>();

  return useCallback(
    (realName: string, signal?: AbortSignal) => {
      return getProductsRealName({
        url: `/products/${realName}`,
        method: 'GET',
        signal,
      });
    },
    [getProductsRealName],
  );
};

export const getGetProductsRealNameQueryKey = (realName: string) => {
  return [`/products/${realName}`] as const;
};

export const useGetProductsRealNameInfiniteQueryOptions = <
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>
  >,
  TError = ErrorType<GetProductsRealName404>,
>(
  realName: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProductsRealNameQueryKey(realName);

  const getProductsRealName = useGetProductsRealNameHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>
  > = ({ signal }) => getProductsRealName(realName, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!realName,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetProductsRealNameInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>
>;
export type GetProductsRealNameInfiniteQueryError =
  ErrorType<GetProductsRealName404>;

export function useGetProductsRealNameInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>
  >,
  TError = ErrorType<GetProductsRealName404>,
>(
  realName: string,
  options: {
    query: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
          TError,
          Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>
        >,
        'initialData'
      >;
  },
): DefinedUseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
};
export function useGetProductsRealNameInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>
  >,
  TError = ErrorType<GetProductsRealName404>,
>(
  realName: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
          TError,
          Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>
        >,
        'initialData'
      >;
  },
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
};
export function useGetProductsRealNameInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>
  >,
  TError = ErrorType<GetProductsRealName404>,
>(
  realName: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
        TError,
        TData
      >
    >;
  },
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
};
/**
 * @summary Получать группу товаров
 */

export function useGetProductsRealNameInfinite<
  TData = InfiniteData<
    Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>
  >,
  TError = ErrorType<GetProductsRealName404>,
>(
  realName: string,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
        TError,
        TData
      >
    >;
  },
): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
} {
  const queryOptions = useGetProductsRealNameInfiniteQueryOptions(
    realName,
    options,
  );

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useGetProductsRealNameQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
  TError = ErrorType<GetProductsRealName404>,
>(
  realName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
        TError,
        TData
      >
    >;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProductsRealNameQueryKey(realName);

  const getProductsRealName = useGetProductsRealNameHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>
  > = ({ signal }) => getProductsRealName(realName, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!realName,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetProductsRealNameQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>
>;
export type GetProductsRealNameQueryError = ErrorType<GetProductsRealName404>;

export function useGetProductsRealName<
  TData = Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
  TError = ErrorType<GetProductsRealName404>,
>(
  realName: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
          TError,
          Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>
        >,
        'initialData'
      >;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
};
export function useGetProductsRealName<
  TData = Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
  TError = ErrorType<GetProductsRealName404>,
>(
  realName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
          TError,
          Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>
        >,
        'initialData'
      >;
  },
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
};
export function useGetProductsRealName<
  TData = Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
  TError = ErrorType<GetProductsRealName404>,
>(
  realName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
};
/**
 * @summary Получать группу товаров
 */

export function useGetProductsRealName<
  TData = Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
  TError = ErrorType<GetProductsRealName404>,
>(
  realName: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetProductsRealNameHook>>>,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
} {
  const queryOptions = useGetProductsRealNameQueryOptions(realName, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Получать все товары
 */
export const usePostProductsHook = () => {
  const postProducts = useCustomClient<PostProducts200>();

  return useCallback(
    (postProductsBody: BodyType<PostProductsBody>, signal?: AbortSignal) => {
      return postProducts({
        url: '/products',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: postProductsBody,
        signal,
      });
    },
    [postProducts],
  );
};

export const usePostProductsMutationOptions = <
  TError = ErrorType<PostProducts400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostProductsHook>>>,
    TError,
    { data: BodyType<PostProductsBody> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostProductsHook>>>,
  TError,
  { data: BodyType<PostProductsBody> },
  TContext
> => {
  const mutationKey = ['postProducts'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const postProducts = usePostProductsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostProductsHook>>>,
    { data: BodyType<PostProductsBody> }
  > = props => {
    const { data } = props ?? {};

    return postProducts(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProductsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostProductsHook>>>
>;
export type PostProductsMutationBody = BodyType<PostProductsBody>;
export type PostProductsMutationError = ErrorType<PostProducts400>;

/**
 * @summary Получать все товары
 */
export const usePostProducts = <
  TError = ErrorType<PostProducts400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostProductsHook>>>,
    TError,
    { data: BodyType<PostProductsBody> },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePostProductsHook>>>,
  TError,
  { data: BodyType<PostProductsBody> },
  TContext
> => {
  const mutationOptions = usePostProductsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Получать пачку групп
 */
export const usePostProductsPacksHook = () => {
  const postProductsPacks = useCustomClient<ItemsPackResponse>();

  return useCallback(
    (
      postProductsPacksBody: BodyType<PostProductsPacksBody>,
      signal?: AbortSignal,
    ) => {
      return postProductsPacks({
        url: '/products/packs',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: postProductsPacksBody,
        signal,
      });
    },
    [postProductsPacks],
  );
};

export const usePostProductsPacksMutationOptions = <
  TError = ErrorType<PostProductsPacks400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostProductsPacksHook>>>,
    TError,
    { data: BodyType<PostProductsPacksBody> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostProductsPacksHook>>>,
  TError,
  { data: BodyType<PostProductsPacksBody> },
  TContext
> => {
  const mutationKey = ['postProductsPacks'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const postProductsPacks = usePostProductsPacksHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostProductsPacksHook>>>,
    { data: BodyType<PostProductsPacksBody> }
  > = props => {
    const { data } = props ?? {};

    return postProductsPacks(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProductsPacksMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostProductsPacksHook>>>
>;
export type PostProductsPacksMutationBody = BodyType<PostProductsPacksBody>;
export type PostProductsPacksMutationError = ErrorType<PostProductsPacks400>;

/**
 * @summary Получать пачку групп
 */
export const usePostProductsPacks = <
  TError = ErrorType<PostProductsPacks400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostProductsPacksHook>>>,
    TError,
    { data: BodyType<PostProductsPacksBody> },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePostProductsPacksHook>>>,
  TError,
  { data: BodyType<PostProductsPacksBody> },
  TContext
> => {
  const mutationOptions = usePostProductsPacksMutationOptions(options);

  return useMutation(mutationOptions);
};
