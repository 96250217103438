'use client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { Skeleton, Typography } from '@/shared/ui';
import { useStore } from '@/store/context';

import stylesDesktop from './card-item-info.module.scss';
import { CardItemInfoProps } from './card-item-info.types';
export const CardItemInfo = observer(
  ({
    customStyles,
    info: {
      title,
      subtitle,
      price: { old, current },
    },
    onTitleClick = () => {},
    applyHandleClickOnFullCard = false,
    className,
  }: CardItemInfoProps) => {
    const styles = customStyles || stylesDesktop;
    const currencySign = useStore().app.getCurrencySign;
    const isClient = useIsClient();
    const isHydrated = useStore().app.isHydrated && isClient;

    return (
      <div className={cn(styles['info-block'], className)}>
        <div className={cn(styles['info-content'])}>
          <Typography onClick={onTitleClick} className={styles['info-title']}>
            {title}
          </Typography>
          {subtitle && (
            <Typography onClick={onTitleClick} className={styles['info-age']}>
              {subtitle}
            </Typography>
          )}

          <div
            onClick={applyHandleClickOnFullCard ? onTitleClick : undefined}
            className={styles['info-price-tags']}
          >
            <Typography className={styles['info-price-current']}>
              {currencySign}
              {Number(current).toFixed(2)}
            </Typography>
            {Boolean(old) && (
              <Typography
                className={styles['info-price-old']}
                decoration={'line-through'}
              >
                {currencySign}
                {Number(old).toFixed(2)}
              </Typography>
            )}
          </div>
        </div>
      </div>
    );
  },
);
