import { useCart } from '@hooks/queries/use-query-cart';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCookie } from 'cookies-next';
import { usePathname, useRouter } from 'next/navigation';

import { logOut } from '@/shared/api/user/queries';
import { isRouteProtected } from '@/shared/constants/routes';

export const useLogout = () => {
  const path = usePathname();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { refetch } = useCart();

  return useMutation({
    mutationFn: logOut,
    onSuccess: () => {
      deleteCookie('jwt');
      queryClient.setQueryData(['user'], null);
      if (isRouteProtected(path)) {
        router.replace('/');
      }
      refetch();
    },
  });
};
