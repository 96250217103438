export const formatAge = (input: string | null) => {
  if (!input) return '';

  return input
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-');
};

export const roundToHundredths = (value: any) => {
  if (!value) return 0;
  return ((Number(value) * 100) / 100).toFixed(2);
};
