'use client';
import { AdjustItemQuantityFeature } from '@features/adjust-item-quantity';
import { useCart } from '@hooks/queries/use-query-cart';
import cn from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useMemo } from 'react';

import { Nullable } from '@/shared/types/common';
import { Button } from '@/shared/ui/button';
import { Illustration } from '@/shared/ui/illustration';

import styles from './add-to-cart.module.scss';

export type AddToCartFeatureProps = {
  id: number;
  price: number;
  prices: Nullable<number[]>;
  selected?: boolean;
  name: string;
};

export const AddToCartFeature = observer(
  ({ id, prices, price, selected, name }: AddToCartFeatureProps) => {
    const { items: cartItems } = useCart();
    const { addToCart } = useCart();

    const cartItem = useMemo(
      () =>
        cartItems.find(
          currentItem => currentItem.id.toString() === id.toString(),
        ),
      [id, cartItems],
    );

    const handleActionButtonClick = (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
      e?.stopPropagation();
      addToCart({
        id: id,
        count: 1,
        prices: prices!,
        selected: true,
        price: price,
      });
    };

    if (cartItem) {
      return (
        <AdjustItemQuantityFeature
          id={id}
          price={price}
          prices={prices!}
          selected={selected!}
          minQuantity={0}
          realName={name}
          initialCount={cartItem.count}
          maxQuantity={10}
          className={styles['quantity-container']}
        />
      );
    }

    return (
      <Button
        as={motion.button}
        whileTap={{ scale: 0.98 }}
        iconLeft={<Illustration name={'cart-plus'} spriteName={'icons'} />}
        width={'full-w'}
        className={cn(styles['action-btn'])}
        onClick={handleActionButtonClick}
        text={'Add to cart'}
      />
    );
  },
);
