'use client';

import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';

import { useLogout } from '@/shared/api/user/useLogout';
import { useUser } from '@/shared/api/user/useUser';
import { DropList } from '@/shared/components/input-search/types';
import { Avatar } from '@/shared/ui';
import { Illustration } from '@/shared/ui';
import { Divider } from '@/shared/ui';
import { DropDownWrapper } from '@/shared/ui/desktop/drop-down-wrapper/drop-down-wrapper';
import { DropListItem } from '@/shared/ui/drop-list-item';
import { Tabs, TabsItemNavigation } from '@/shared/ui/tabs';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './drop-list-profile.module.scss';
import { DropListProfileProps } from './types';

const menu: DropList<string>[] = [
  {
    label: 'Profile',
    value: 'account',
    icon: (
      <Illustration
        name={'user'}
        spanTagClassName={styles['item-icon']}
        spriteName={'icons'}
        style={{ color: '#6941C6' }}
      />
    ),
  },
  /*  {
      label: 'Support',
      value: 'egg',
      icon: (
        <Illustration
          name={'headphones'}
          spanTagClassName={styles['item-icon']}
          spriteName={'icons'}
          style={{ color: '#6941C6' }}
        />
      ),
    },*/
  {
    label: 'History',
    value: 'history/purchases',
    icon: (
      <Illustration
        name={'clock'}
        spanTagClassName={styles['item-icon']}
        spriteName={'icons'}
        style={{ color: '#6941C6' }}
      />
    ),
  },
  {
    label: 'Gift',
    value: 'drop',
    icon: (
      <Illustration
        name={'gift'}
        spanTagClassName={styles['item-icon']}
        spriteName={'icons'}
        style={{ color: '#6941C6' }}
      />
    ),
  },
  /*{
    label: 'Settings',
    value: 'settings',
    icon: (
      <Illustration
        name={'settings'}
        spanTagClassName={styles['item-icon']}
        spriteName={'icons'}
        style={{ color: '#6941C6' }}
      />
    ),
  },*/

  {
    label: 'FAQ',
    value: 'faq',
    icon: (
      <Illustration
        name={'question-square'}
        spanTagClassName={styles['item-icon']}
        spriteName={'icons'}
        style={{ color: '#6941C6' }}
      />
    ),
  },
  {
    label: 'Log Out',
    value: 'logout',
    icon: (
      <Illustration
        name={'exit'}
        spanTagClassName={styles['item-icon']}
        spriteName={'icons'}
        style={{ color: '#EB3C3C' }}
      />
    ),
  },
];

export const DropListProfile: React.FC<DropListProfileProps> = observer(
  ({}) => {
    const [dropListOpened, setDropListOpened] = useState(false);
    const auth = useStore().authorization;
    const logOutMutation = useLogout();
    const { user } = useUser();
    const appStore = useStore().app;
    const selectedCurrency = appStore.selectedCurrency;
    const currencySign = appStore.getCurrencySign;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const router = useRouter();
    const toggleDropDown = () => {
      setDropListOpened(!dropListOpened);
    };

    const renderMenu = (item: DropList<string>, index: number) => {
      return (
        <DropListItem
          className={styles['drop_list-item']}
          key={index}
          iconLeft={item.icon}
          title={item.label}
          onPress={() => {
            if (item.value === 'account' || item.value.includes('history')) {
              router.push(`/profile/${item.value}`);
            }
            if (item.value === 'logout') {
              logOutMutation.mutate();
            }
            if (item.value === 'drop') {
              router.push('/drop');
            }
            if (item.value === 'faq') {
              router.push('/faq');
            }
            setDropListOpened(false);
          }}
        />
      );
    };

    if (user) {
      return (
        <div className={styles.container} onClick={toggleDropDown}>
          <div className={styles['container-header']}>
            <Avatar
              src={
                user.avatarUrl ||
                'https://i.pravatar.cc/150?u=a042581f4e29026024d'
              }
            />
          </div>
          <DropDownWrapper
            close={() => setDropListOpened(false)}
            isOpened={dropListOpened}
            className={styles.drop_list}
          >
            <>
              <div className={styles['container-profile_info']}>
                <Typography className={styles['container-profile_info-name']}>
                  {user.displayName || user.username || ''}
                </Typography>
                <Typography
                  className={styles['container-profile_info-balance']}
                >
                  {currencySign} 0.00
                </Typography>
              </div>
              <Divider direction="horizontal" />
              {menu.map(renderMenu)}
            </>
          </DropDownWrapper>
        </div>
      );
    }

    return (
      <Tabs<string>
        className={styles.tabs}
        onTabsChange={() => auth.openSignIn()}
      >
        <TabsItemNavigation
          isActive={false}
          key={'profile'}
          icon={
            <Illustration
              name={'user'}
              spriteName={'icons'}
              size={30}
              style={{ color: '#FE9920' }}
            />
          }
          value={'profile'}
        >
          {'Profile'}
        </TabsItemNavigation>
      </Tabs>
    );
  },
);
