/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * API Сервиса Маркет (Adopt Me) #market #adopt_me
 * OpenAPI spec version: 1.0.0
 */
import { CartItemsResponse } from '@hooks/queries/common.types';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseInfiniteQueryResult,
  DefinedUseQueryResult,
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { CartItem } from '@/store/cart/cart';

import type { BodyType, ErrorType } from '../../axios-client';
import { useCustomClient } from '../../axios-client';
import type {
  DeleteCartsId200,
  DeleteCartsId400,
  DeleteCartsId401,
  DeleteCartsId429,
  DeleteCartsSelected200,
  DeleteCartsSelected401,
  DeleteCartsSelected429,
  DeleteCartsUnavailable200,
  DeleteCartsUnavailable401,
  DeleteCartsUnavailable429,
  DeleteCartsUnavailableId200,
  DeleteCartsUnavailableId400,
  DeleteCartsUnavailableId401,
  DeleteCartsUnavailableId429,
  GetCarts401,
  GetCarts429,
  PostCartsAdd200,
  PostCartsAdd400,
  PostCartsAdd401,
  PostCartsAdd429,
  PostCartsAddBody,
  PostCartsBuy400,
  PostCartsBuyBody,
  PostCartsMerge200,
  PostCartsMerge400,
  PostCartsMerge401,
  PostCartsMerge429,
  PostCartsMergeBody,
  PutCartsSelectAll200,
  PutCartsSelectAll401,
  PutCartsSelectAll429,
  PutCartsSelectId200,
  PutCartsSelectId400,
  PutCartsSelectId401,
  PutCartsSelectId429,
  PutCartsUnselectAll200,
  PutCartsUnselectAll401,
  PutCartsUnselectAll429,
  PutCartsUnselectId200,
  PutCartsUnselectId400,
  PutCartsUnselectId401,
  PutCartsUnselectId429,
} from '.././models';

/**
 * @summary Получать корзину
 */
export const useGetCartsHook = () => {
  const getCarts = useCustomClient<CartItemsResponse>();

  return useCallback(
    (signal?: AbortSignal) => {
      return getCarts({ url: '/carts', method: 'GET', signal });
    },
    [getCarts],
  );
};

export const getGetCartsQueryKey = () => {
  return ['/carts'] as const;
};

export const useGetCartsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>>,
  TError = ErrorType<GetCarts401 | GetCarts429>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCartsQueryKey();

  const getCarts = useGetCartsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>
  > = ({ signal }) => getCarts(signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetCartsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>
>;
export type GetCartsInfiniteQueryError = ErrorType<GetCarts401 | GetCarts429>;

export function useGetCartsInfinite<
  TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>>,
  TError = ErrorType<GetCarts401 | GetCarts429>,
>(options: {
  query: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
        TError,
        Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>
      >,
      'initialData'
    >;
}): DefinedUseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
};
export function useGetCartsInfinite<
  TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>>,
  TError = ErrorType<GetCarts401 | GetCarts429>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
        TError,
        Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>
      >,
      'initialData'
    >;
}): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
};
export function useGetCartsInfinite<
  TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>>,
  TError = ErrorType<GetCarts401 | GetCarts429>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
      TError,
      TData
    >
  >;
}): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
};
/**
 * @summary Получать корзину
 */

export function useGetCartsInfinite<
  TData = InfiniteData<Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>>,
  TError = ErrorType<GetCarts401 | GetCarts429>,
>(options?: {
  query?: Partial<
    UseInfiniteQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
      TError,
      TData
    >
  >;
}): UseInfiniteQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
} {
  const queryOptions = useGetCartsInfiniteQueryOptions(options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const useGetCartsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
  TError = ErrorType<GetCarts401 | GetCarts429>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCartsQueryKey();

  const getCarts = useGetCartsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>
  > = ({ signal }) => getCarts(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData, TError> };
};

export type GetCartsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>
>;
export type GetCartsQueryError = ErrorType<GetCarts401 | GetCarts429>;

export function useGetCarts<
  TData = Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
  TError = ErrorType<GetCarts401 | GetCarts429>,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
        TError,
        Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>
      >,
      'initialData'
    >;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
};
export function useGetCarts<
  TData = Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
  TError = ErrorType<GetCarts401 | GetCarts429>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
        TError,
        Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>
      >,
      'initialData'
    >;
}): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
};
export function useGetCarts<
  TData = Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
  TError = ErrorType<GetCarts401 | GetCarts429>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
};
/**
 * @summary Получать корзину
 */

export function useGetCarts<
  TData = Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
  TError = ErrorType<GetCarts401 | GetCarts429>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCartsHook>>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData, TError>;
} {
  const queryOptions = useGetCartsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData, TError>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Добавить товар в корзину
 */
export const usePostCartsAddHook = () => {
  const postCartsAdd = useCustomClient<CartItemsResponse>();

  return useCallback(
    (postCartsAddBody: BodyType<PostCartsAddBody>, signal?: AbortSignal) => {
      return postCartsAdd({
        url: '/carts/add',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: postCartsAddBody,
        signal,
      });
    },
    [postCartsAdd],
  );
};

export const usePostCartsAddMutationOptions = <
  TError = ErrorType<PostCartsAdd400 | PostCartsAdd401 | PostCartsAdd429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostCartsAddHook>>>,
    TError,
    { data: BodyType<PostCartsAddBody> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostCartsAddHook>>>,
  TError,
  { data: BodyType<PostCartsAddBody> },
  TContext
> => {
  const mutationKey = ['postCartsAdd'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const postCartsAdd = usePostCartsAddHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostCartsAddHook>>>,
    { data: BodyType<PostCartsAddBody> }
  > = props => {
    const { data } = props ?? {};

    return postCartsAdd(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCartsAddMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostCartsAddHook>>>
>;
export type PostCartsAddMutationBody = BodyType<PostCartsAddBody>;
export type PostCartsAddMutationError = ErrorType<
  PostCartsAdd400 | PostCartsAdd401 | PostCartsAdd429
>;

/**
 * @summary Добавить товар в корзину
 */
export const usePostCartsAdd = <
  TError = ErrorType<PostCartsAdd400 | PostCartsAdd401 | PostCartsAdd429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostCartsAddHook>>>,
    TError,
    { data: BodyType<PostCartsAddBody> },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePostCartsAddHook>>>,
  TError,
  { data: BodyType<PostCartsAddBody> },
  TContext
> => {
  const mutationOptions = usePostCartsAddMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Удалить товар
 */
export const useDeleteCartsIdHook = () => {
  const deleteCartsId = useCustomClient<DeleteCartsId200>();

  return useCallback(
    (id: number) => {
      return deleteCartsId({ url: `/carts/${id}`, method: 'DELETE' });
    },
    [deleteCartsId],
  );
};

export const useDeleteCartsIdMutationOptions = <
  TError = ErrorType<DeleteCartsId400 | DeleteCartsId401 | DeleteCartsId429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCartsIdHook>>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteCartsIdHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationKey = ['deleteCartsId'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const deleteCartsId = useDeleteCartsIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteCartsIdHook>>>,
    { id: number }
  > = props => {
    const { id } = props ?? {};

    return deleteCartsId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCartsIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteCartsIdHook>>>
>;

export type DeleteCartsIdMutationError = ErrorType<
  DeleteCartsId400 | DeleteCartsId401 | DeleteCartsId429
>;

/**
 * @summary Удалить товар
 */
export const useDeleteCartsId = <
  TError = ErrorType<DeleteCartsId400 | DeleteCartsId401 | DeleteCartsId429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCartsIdHook>>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useDeleteCartsIdHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = useDeleteCartsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Удалить товар из недоступных, и есть в доступных
 */
export const useDeleteCartsUnavailableIdHook = () => {
  const deleteCartsUnavailableId =
    useCustomClient<DeleteCartsUnavailableId200>();

  return useCallback(
    (id: number) => {
      return deleteCartsUnavailableId({
        url: `/carts/unavailable/${id}`,
        method: 'DELETE',
      });
    },
    [deleteCartsUnavailableId],
  );
};

export const useDeleteCartsUnavailableIdMutationOptions = <
  TError = ErrorType<
    | DeleteCartsUnavailableId400
    | DeleteCartsUnavailableId401
    | DeleteCartsUnavailableId429
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCartsUnavailableIdHook>>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteCartsUnavailableIdHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationKey = ['deleteCartsUnavailableId'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const deleteCartsUnavailableId = useDeleteCartsUnavailableIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteCartsUnavailableIdHook>>>,
    { id: number }
  > = props => {
    const { id } = props ?? {};

    return deleteCartsUnavailableId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCartsUnavailableIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteCartsUnavailableIdHook>>>
>;

export type DeleteCartsUnavailableIdMutationError = ErrorType<
  | DeleteCartsUnavailableId400
  | DeleteCartsUnavailableId401
  | DeleteCartsUnavailableId429
>;

/**
 * @summary Удалить товар из недоступных, и есть в доступных
 */
export const useDeleteCartsUnavailableId = <
  TError = ErrorType<
    | DeleteCartsUnavailableId400
    | DeleteCartsUnavailableId401
    | DeleteCartsUnavailableId429
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCartsUnavailableIdHook>>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useDeleteCartsUnavailableIdHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = useDeleteCartsUnavailableIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Удалить выбранные товары
 */
export const useDeleteCartsSelectedHook = () => {
  const deleteCartsSelected = useCustomClient<DeleteCartsSelected200>();

  return useCallback(() => {
    return deleteCartsSelected({ url: '/carts/selected', method: 'DELETE' });
  }, [deleteCartsSelected]);
};

export const useDeleteCartsSelectedMutationOptions = <
  TError = ErrorType<DeleteCartsSelected401 | DeleteCartsSelected429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCartsSelectedHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteCartsSelectedHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationKey = ['deleteCartsSelected'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const deleteCartsSelected = useDeleteCartsSelectedHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteCartsSelectedHook>>>,
    void
  > = () => {
    return deleteCartsSelected();
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCartsSelectedMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteCartsSelectedHook>>>
>;

export type DeleteCartsSelectedMutationError = ErrorType<
  DeleteCartsSelected401 | DeleteCartsSelected429
>;

/**
 * @summary Удалить выбранные товары
 */
export const useDeleteCartsSelected = <
  TError = ErrorType<DeleteCartsSelected401 | DeleteCartsSelected429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCartsSelectedHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useDeleteCartsSelectedHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = useDeleteCartsSelectedMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Удалить недоступные товары
 */
export const useDeleteCartsUnavailableHook = () => {
  const deleteCartsUnavailable = useCustomClient<DeleteCartsUnavailable200>();

  return useCallback(() => {
    return deleteCartsUnavailable({
      url: '/carts/unavailable',
      method: 'DELETE',
    });
  }, [deleteCartsUnavailable]);
};

export const useDeleteCartsUnavailableMutationOptions = <
  TError = ErrorType<DeleteCartsUnavailable401 | DeleteCartsUnavailable429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCartsUnavailableHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteCartsUnavailableHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationKey = ['deleteCartsUnavailable'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const deleteCartsUnavailable = useDeleteCartsUnavailableHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteCartsUnavailableHook>>>,
    void
  > = () => {
    return deleteCartsUnavailable();
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCartsUnavailableMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteCartsUnavailableHook>>>
>;

export type DeleteCartsUnavailableMutationError = ErrorType<
  DeleteCartsUnavailable401 | DeleteCartsUnavailable429
>;

/**
 * @summary Удалить недоступные товары
 */
export const useDeleteCartsUnavailable = <
  TError = ErrorType<DeleteCartsUnavailable401 | DeleteCartsUnavailable429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteCartsUnavailableHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useDeleteCartsUnavailableHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = useDeleteCartsUnavailableMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Выбрать товар
 */
export const usePutCartsSelectIdHook = () => {
  const putCartsSelectId = useCustomClient<PutCartsSelectId200>();

  return useCallback(
    (id: number) => {
      return putCartsSelectId({ url: `/carts/select/${id}`, method: 'PUT' });
    },
    [putCartsSelectId],
  );
};

export const usePutCartsSelectIdMutationOptions = <
  TError = ErrorType<
    PutCartsSelectId400 | PutCartsSelectId401 | PutCartsSelectId429
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutCartsSelectIdHook>>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutCartsSelectIdHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationKey = ['putCartsSelectId'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const putCartsSelectId = usePutCartsSelectIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutCartsSelectIdHook>>>,
    { id: number }
  > = props => {
    const { id } = props ?? {};

    return putCartsSelectId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCartsSelectIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutCartsSelectIdHook>>>
>;

export type PutCartsSelectIdMutationError = ErrorType<
  PutCartsSelectId400 | PutCartsSelectId401 | PutCartsSelectId429
>;

/**
 * @summary Выбрать товар
 */
export const usePutCartsSelectId = <
  TError = ErrorType<
    PutCartsSelectId400 | PutCartsSelectId401 | PutCartsSelectId429
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutCartsSelectIdHook>>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePutCartsSelectIdHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = usePutCartsSelectIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Снять выбор товара
 */
export const usePutCartsUnselectIdHook = () => {
  const putCartsUnselectId = useCustomClient<PutCartsUnselectId200>();

  return useCallback(
    (id: number) => {
      return putCartsUnselectId({
        url: `/carts/unselect/${id}`,
        method: 'PUT',
      });
    },
    [putCartsUnselectId],
  );
};

export const usePutCartsUnselectIdMutationOptions = <
  TError = ErrorType<
    PutCartsUnselectId400 | PutCartsUnselectId401 | PutCartsUnselectId429
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutCartsUnselectIdHook>>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutCartsUnselectIdHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationKey = ['putCartsUnselectId'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const putCartsUnselectId = usePutCartsUnselectIdHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutCartsUnselectIdHook>>>,
    { id: number }
  > = props => {
    const { id } = props ?? {};

    return putCartsUnselectId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCartsUnselectIdMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutCartsUnselectIdHook>>>
>;

export type PutCartsUnselectIdMutationError = ErrorType<
  PutCartsUnselectId400 | PutCartsUnselectId401 | PutCartsUnselectId429
>;

/**
 * @summary Снять выбор товара
 */
export const usePutCartsUnselectId = <
  TError = ErrorType<
    PutCartsUnselectId400 | PutCartsUnselectId401 | PutCartsUnselectId429
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutCartsUnselectIdHook>>>,
    TError,
    { id: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePutCartsUnselectIdHook>>>,
  TError,
  { id: number },
  TContext
> => {
  const mutationOptions = usePutCartsUnselectIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Выбрать все товары
 */
export const usePutCartsSelectAllHook = () => {
  const putCartsSelectAll = useCustomClient<PutCartsSelectAll200>();

  return useCallback(() => {
    return putCartsSelectAll({ url: '/carts/select-all', method: 'PUT' });
  }, [putCartsSelectAll]);
};

export const usePutCartsSelectAllMutationOptions = <
  TError = ErrorType<PutCartsSelectAll401 | PutCartsSelectAll429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutCartsSelectAllHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutCartsSelectAllHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationKey = ['putCartsSelectAll'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const putCartsSelectAll = usePutCartsSelectAllHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutCartsSelectAllHook>>>,
    void
  > = () => {
    return putCartsSelectAll();
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCartsSelectAllMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutCartsSelectAllHook>>>
>;

export type PutCartsSelectAllMutationError = ErrorType<
  PutCartsSelectAll401 | PutCartsSelectAll429
>;

/**
 * @summary Выбрать все товары
 */
export const usePutCartsSelectAll = <
  TError = ErrorType<PutCartsSelectAll401 | PutCartsSelectAll429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutCartsSelectAllHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePutCartsSelectAllHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = usePutCartsSelectAllMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Снять выбор всех товаров
 */
export const usePutCartsUnselectAllHook = () => {
  const putCartsUnselectAll = useCustomClient<PutCartsUnselectAll200>();

  return useCallback(() => {
    return putCartsUnselectAll({ url: '/carts/unselect-all', method: 'PUT' });
  }, [putCartsUnselectAll]);
};

export const usePutCartsUnselectAllMutationOptions = <
  TError = ErrorType<PutCartsUnselectAll401 | PutCartsUnselectAll429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutCartsUnselectAllHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutCartsUnselectAllHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationKey = ['putCartsUnselectAll'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const putCartsUnselectAll = usePutCartsUnselectAllHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutCartsUnselectAllHook>>>,
    void
  > = () => {
    return putCartsUnselectAll();
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCartsUnselectAllMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutCartsUnselectAllHook>>>
>;

export type PutCartsUnselectAllMutationError = ErrorType<
  PutCartsUnselectAll401 | PutCartsUnselectAll429
>;

/**
 * @summary Снять выбор всех товаров
 */
export const usePutCartsUnselectAll = <
  TError = ErrorType<PutCartsUnselectAll401 | PutCartsUnselectAll429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutCartsUnselectAllHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePutCartsUnselectAllHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = usePutCartsUnselectAllMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Объединить корзины
 */
export const usePostCartsMergeHook = () => {
  const postCartsMerge = useCustomClient<PostCartsMerge200>();

  return useCallback(
    (
      postCartsMergeBody: BodyType<
        Pick<CartItem, 'id' | 'count' | 'selected'>[]
      >,
      signal?: AbortSignal,
    ) => {
      return postCartsMerge({
        url: '/carts/merge',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: postCartsMergeBody,
        signal,
      });
    },
    [postCartsMerge],
  );
};

export const usePostCartsMergeMutationOptions = <
  TError = ErrorType<PostCartsMerge400 | PostCartsMerge401 | PostCartsMerge429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostCartsMergeHook>>>,
    TError,
    { data: BodyType<PostCartsMergeBody> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostCartsMergeHook>>>,
  TError,
  { data: BodyType<PostCartsMergeBody> },
  TContext
> => {
  const mutationKey = ['postCartsMerge'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const postCartsMerge = usePostCartsMergeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostCartsMergeHook>>>,
    { data: BodyType<PostCartsMergeBody> }
  > = props => {
    const { data } = props ?? {};

    return postCartsMerge(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCartsMergeMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostCartsMergeHook>>>
>;
export type PostCartsMergeMutationBody = BodyType<PostCartsMergeBody>;
export type PostCartsMergeMutationError = ErrorType<
  PostCartsMerge400 | PostCartsMerge401 | PostCartsMerge429
>;

/**
 * @summary Объединить корзины
 */
export const usePostCartsMerge = <
  TError = ErrorType<PostCartsMerge400 | PostCartsMerge401 | PostCartsMerge429>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostCartsMergeHook>>>,
    TError,
    { data: BodyType<PostCartsMergeBody> },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePostCartsMergeHook>>>,
  TError,
  { data: BodyType<PostCartsMergeBody> },
  TContext
> => {
  const mutationOptions = usePostCartsMergeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This endpoint makes an HTTP GET request to retrieve a JSON content example based on the provided Joi schema for body validation.
 * @summary [Анонс]: Покупка корзины
 */
export const usePostCartsBuyHook = () => {
  const postCartsBuy = useCustomClient<unknown>();

  return useCallback(
    (postCartsBuyBody: BodyType<PostCartsBuyBody>, signal?: AbortSignal) => {
      return postCartsBuy({
        url: '/carts/buy',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: postCartsBuyBody,
        signal,
      });
    },
    [postCartsBuy],
  );
};

export const usePostCartsBuyMutationOptions = <
  TError = ErrorType<PostCartsBuy400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostCartsBuyHook>>>,
    TError,
    { data: BodyType<PostCartsBuyBody> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostCartsBuyHook>>>,
  TError,
  { data: BodyType<PostCartsBuyBody> },
  TContext
> => {
  const mutationKey = ['postCartsBuy'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const postCartsBuy = usePostCartsBuyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostCartsBuyHook>>>,
    { data: BodyType<PostCartsBuyBody> }
  > = props => {
    const { data } = props ?? {};

    return postCartsBuy(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCartsBuyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostCartsBuyHook>>>
>;
export type PostCartsBuyMutationBody = BodyType<PostCartsBuyBody>;
export type PostCartsBuyMutationError = ErrorType<PostCartsBuy400>;

/**
 * @summary [Анонс]: Покупка корзины
 */
export const usePostCartsBuy = <
  TError = ErrorType<PostCartsBuy400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostCartsBuyHook>>>,
    TError,
    { data: BodyType<PostCartsBuyBody> },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePostCartsBuyHook>>>,
  TError,
  { data: BodyType<PostCartsBuyBody> },
  TContext
> => {
  const mutationOptions = usePostCartsBuyMutationOptions(options);

  return useMutation(mutationOptions);
};
