import { ShopCards } from '@widgets/desktop';
import { ShopCardsVirtualized } from '@widgets/desktop/list-cards/ui/shop-cards-virtualized';
import cn from 'clsx';
import dynamic from 'next/dynamic';
import React from 'react';

import { CartWidgetPurchaseCheckoutProps } from '@/app/[locale]/cart/types';
import { Divider } from '@/shared/ui';

import styles from './content.module.scss';

const ShopSorting = dynamic(
  () => import('../sorting').then(mod => mod.ShopSorting),
  {
    ssr: true,
  },
);

const ShopSelectedFilters = dynamic(
  () => import('../selected-filters').then(mod => mod.ShopSelectedFilters),
  {
    ssr: true,
  },
);

export const ShopContent = ({ className }: CartWidgetPurchaseCheckoutProps) => {
  const contentClasses = cn(styles.content, className);

  return (
    <div className={contentClasses}>
      <div className={styles.header}>
        <ShopSorting />
        <ShopSelectedFilters />
      </div>
      <Divider direction="horizontal" />
      <div
        id={'scroller'}
        style={{ display: 'flex', justifyContent: 'center', height: '100dvh' }}
      >
        <ShopCardsVirtualized />
      </div>
      {/* <ShopCards />*/}
    </div>
  );
};
