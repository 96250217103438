import { CardItemOfferEntity } from '@entities/desktop/cards';
import { HistoryItem } from '@widgets/desktop/inventory-history/ui/history.types';
import React from 'react';

import { Divider, Illustration, Typography } from '@/shared/ui';
import {
  AdditionalInfoMobile,
  AdditionalInfoMobileTimer,
} from '@/shared/ui/mobile/additional-info';

import styles from './history.module.scss';

export const historyItems: HistoryItem[] = [
  {
    rare: 'legendary',
    type: 'egg',
    realName: 'royal_egg',
    game: 'adopt',
    name: 'Royal Egg',
    subtype: null,
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/royal_egg_218155608038015716861250016536746955046.png',
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    id: 27271,
    price: 3.22,
    age: null,
    flyable: null,
    rideable: null,
    pumping: null,

    status: 'canceled',
    asGift: true,
  },
  {
    rare: 'common',
    type: 'transport',
    game: 'adopt',
    realName: 'neon_black_scooter',
    name: 'Neon Black Scooter',
    subtype: 'scooters',
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/neon_black_scooter_181279737671050341577192616449886507435.png',
    description:
      'The Caravan Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    id: 27448,
    price: 43.32,
    age: null,
    flyable: null,
    rideable: null,
    pumping: null,

    status: 'canceled',
    asGift: true,
  },
  {
    rare: 'uncommon',
    type: 'pet',
    game: 'adopt',
    realName: 'halloween_2021_black_mummy_cat',
    name: 'Halloween Black Mummy Cat',
    subtype: null,
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/halloween_2021_black_mummy_cat_53705332753530036708937767565677110703.png',
    description:
      'The Baku Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    id: 33573,
    price: 144,
    age: 'newborn',
    flyable: true,
    rideable: true,
    pumping: 'default',

    status: 'completed',
    asGift: false,
  },
  {
    rare: 'uncommon',
    type: 'pet',
    game: 'adopt',
    realName: 'basic_egg_2022_poodle',
    name: 'Poodle',
    subtype: null,
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/basic_egg_2022_poodle_10009173492233461134036485935467892038.png',
    id: 43597,
    price: 33,
    description:
      'The Woodland-egg Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    age: 'reborn',
    flyable: true,
    rideable: true,
    pumping: 'neon',

    status: 'completed',
    asGift: false,
  },
];

export const InventoryHistoryWidget: React.FC = () => {
  const renderOfferItem = (item: HistoryItem, index: number) => {
    const { asGift, id, ...rest } = item;

    return (
      <React.Fragment key={`offer-${item?.realName}-${index}-${item?.rare}`}>
        <CardItemOfferEntity
          {...rest}
          bottomSlot={
            <AdditionalInfoMobileTimer status={item.status} duration={0} />
          } //@TODO мобильный адишнл в десктопе :(
        />
        {index !== historyItems.length - 1 && (
          <Divider style={{ minHeight: 1 }} direction={'horizontal'} />
        )}
      </React.Fragment>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles['header-title-container']}>
          <Illustration
            name={'clock-green'}
            size={26}
            style={{ color: 'rgba(105, 65, 198, 1)' }}
            spriteName={'icons'}
          />
          <Typography className={styles['header-title-text']}>
            History
          </Typography>
        </div>
        <Typography
          className={styles['header-title-text']}
        >{`${historyItems.length} items`}</Typography>
      </div>
      <div className={styles['scroll-container']}>
        {historyItems.map(renderOfferItem)}
      </div>
    </div>
  );
};
