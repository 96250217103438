'use client';
import {
  useDeleteCartsIdHook,
  useDeleteCartsSelectedHook,
  useDeleteCartsUnavailableHook,
  useDeleteCartsUnavailableIdHook,
  useGetCartsHook,
  usePostCartsAddHook,
  usePostCartsMergeHook,
  usePutCartsSelectAllHook,
  usePutCartsSelectIdHook,
  usePutCartsUnselectAllHook,
  usePutCartsUnselectIdHook,
} from '@api-product/🧺-корзина-🔓/🧺-корзина-🔓';
import { useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { toggleAllSelected, toggleSelected } from '@utils/cart';
import { getCookie } from 'cookies-next';
import { useIsClient } from 'usehooks-ts';

import { ProductById } from '@/shared/types/common';
import { CartItem } from '@/store/cart/cart';
import { useStore } from '@/store/context';

import { QueryKeys } from './queryKeys';

export const useCart = () => {
  const queryClient = useQueryClient();
  const cartStore = useStore().cart;
  const getCartCallback = useGetCartsHook();
  const addItem = usePostCartsAddHook();
  const mergeRequest = usePostCartsMergeHook();
  const setSelected = usePutCartsSelectIdHook();
  const setUnselected = usePutCartsUnselectIdHook();
  const deleteSelected = useDeleteCartsSelectedHook();
  const unselectAll = usePutCartsUnselectAllHook();
  const selectAll = usePutCartsSelectAllHook();
  const deleteUnavailableSelected = useDeleteCartsUnavailableHook();
  const deleteById = useDeleteCartsIdHook();
  const deleteUnavailable = useDeleteCartsUnavailableIdHook();
  const isClient = useIsClient();

  const { data: responseData, isLoading } = useQuery({
    queryKey: [QueryKeys.CART],
    queryFn: () => getCartCallback(),
    refetchInterval: 60000 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!getCookie('jwt'),
    gcTime: 0,
  });

  const addToCart = async (item: CartItem) => {
    const token = getCookie('jwt');

    if (item.count === 0) {
      await deleteAvailableItem(item.id);
      return;
    }

    if (!token) {
      return cartStore.addToCart(item);
    } else {
      const response = await addItem({
        id: item.id,
        count: item.count,
      });
      queryClient.setQueryData([QueryKeys.CART], response);
      return response.data.list.find(
        cur => cur.id.toString() === item.id.toString(),
      );
    }
  };

  const mergeCarts = async () => {
    const mergeData = cartStore.cart.list.map(item => ({
      id: item.id,
      count: item.count,
      selected: true,
    }));

    if (mergeData.length) {
      await mergeRequest(mergeData);
    }
    cartStore.clearCart();
  };

  const toggleItemSelected = async (item: ProductById) => {
    const token = getCookie('jwt');

    if (!token) {
      cartStore.toggleSelected(item.id);
    } else {
      queryClient.setQueryData([QueryKeys.CART], toggleSelected(item.id));

      if (item.selected) {
        const response = await setUnselected(item.id);
        queryClient.setQueryData([QueryKeys.CART], response);
      } else {
        const response = await setSelected(item.id);
        queryClient.setQueryData([QueryKeys.CART], response);
      }
    }
  };

  const removeSelected = async () => {
    const token = getCookie('jwt');

    if (token) {
      const response = await deleteSelected();
      queryClient.setQueryData([QueryKeys.CART], response);
    } else {
      cartStore.removeAllSelected();
    }
  };

  const toggleAllCartSelected = async (allSelected: boolean) => {
    const token = getCookie('jwt');
    if (token) {
      queryClient.setQueryData(
        [QueryKeys.CART],
        toggleAllSelected(allSelected),
      );
      const response = await (allSelected ? unselectAll() : selectAll());
      queryClient.setQueryData([QueryKeys.CART], response);
    } else {
      cartStore.toggleAllSelected(allSelected);
    }
  };

  const removeAllSoldOut = async () => {
    const token = getCookie('jwt');

    if (token) {
      const response = await deleteUnavailableSelected();
      queryClient.setQueryData([QueryKeys.CART], response);
    } else {
      cartStore.removeSoldout();
    }
  };

  const deleteAvailableItem = async (id: number) => {
    const token = getCookie('jwt');

    if (token) {
      const response = await deleteById(id);
      queryClient.setQueryData([QueryKeys.CART], response);
    } else {
      cartStore.deleteAvailableItem(id);
    }
  };

  const deleteUnavailableItem = async (id: number) => {
    const token = getCookie('jwt');

    if (token) {
      const response = await deleteUnavailable(id);
      queryClient.setQueryData([QueryKeys.CART], response);
    } else {
      cartStore.deleteUnavailableItem(id);
    }
  };

  const refetch = () => {
    queryClient.setQueryData([QueryKeys.CART], null);
  };

  return {
    items:
      (getCookie('jwt') ? responseData?.data.list : cartStore.cart.list) ?? [],
    summary: getCookie('jwt')
      ? responseData?.data.summary
      : cartStore.cart.summary,
    allSelected: getCookie('jwt')
      ? responseData?.data.isAllSelected
      : cartStore.cart.isAllSelected,
    isLoading: isLoading || !isClient,
    mergeCarts,
    addToCart,
    toggleSelected: toggleItemSelected,
    removeSelected,
    removeAllSoldOut,
    deleteAvailableItem,
    deleteUnavailableItem,
    toggleAllCartSelected,
    refetch,
  };
};
