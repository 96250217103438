'use client';
import { ListCardsWidgetProps } from '@widgets/desktop/list-cards/ui/list-cards.types';
import cn from 'clsx';
import React, { forwardRef, useEffect, useState } from 'react';
import { VirtuosoGrid } from 'react-virtuoso';

import { Skeleton } from '@/shared/ui';

import styles from './list-cards.module.scss';

type ListCardsVirtualizedProps = ListCardsWidgetProps & {
  items: any[];
  itemContent: (index: number, item: any) => React.ReactNode;
  loadMore?: () => void;
  isFetchingNextPage?: boolean;
  isFetching?: boolean;
  isLoading?: boolean;
  isInitialLoading?: boolean;
};

const gridComponents = (isLoading?: boolean, cardsVariant?: string) => {
  return {
    List: forwardRef<HTMLDivElement, any>((props, ref) => (
      <div
        ref={ref}
        {...props}
        id={'virtualized-grid-components--list'}
        className={styles['virtualized-grid-components--list']}
        style={{
          ...props.style,
        }}
      >
        {props.children}

        {isLoading &&
          Array(16)
            .fill('')
            .map((_, idx) => (
              <Skeleton
                virtualized
                key={`skeleton-list-cards-${idx}`}
                className={cn(
                  styles['list-card-skeleton'],
                  styles[`variant--${cardsVariant ? cardsVariant : 'primary'}`],
                )}
                containerClassName={
                  styles['virtualized-grid-components--skeleton']
                }
              />
            ))}
      </div>
    )),

    Item: forwardRef<HTMLDivElement, any>((props, ref) => (
      <div
        {...props}
        id={'virtualized-grid-components--item'}
        className={styles['virtualized-grid-components--item']}
      >
        {props.children}
      </div>
    )),
    /*Footer: () => (
    <div
      className={styles['virtualized-grid-components--footer']}
    >

    </div>
  )*/
  };
};

export const ListCardsWidgetVirtualized = ({
  className,
  topSlot,
  cardsVariant,
  isLoading,
  isFetchingNextPage,
  isFetching,
  isInitialLoading,
  items,
  itemContent,
  loadMore,
  ...props
}: ListCardsVirtualizedProps) => {
  const listCardsClasses = cn(styles['list-cards'], className);
  const bottomSlotClasses = cn(
    styles['bottom-slot'],
    styles[`bottom-slot--${cardsVariant}`],
  );

  const [mounted, setMounted] = useState(false);
  const [scrollParent, setScrollParent] = useState<HTMLElement | null>(null);

  // Set up the scroll parent after component mounts
  useEffect(() => {
    // We need to wait until the component is mounted to access window
    setMounted(true);

    // Set document body or documentElement as the scroll parent
    setScrollParent(document.body as HTMLElement);

    return () => {
      // Cleanup
      setScrollParent(null);
    };
  }, []);

  return (
    <div className={listCardsClasses} {...props}>
      {topSlot && <div className={styles['top-slot']}>{topSlot}</div>}
      {mounted && scrollParent && (
        <div style={{ width: '100%' }}>
          <VirtuosoGrid
            className={styles['list']}
            increaseViewportBy={{ top: 100, bottom: 200 }}
            data={items}
            endReached={loadMore}
            overscan={10}
            components={gridComponents(isFetching, cardsVariant)}
            itemContent={(index, item) => itemContent(index, item)}
          />
        </div>
      )}
    </div>
  );
};

/*<Virtuoso
        style={{ height: '500px' }}
        data={items}
        endReached={loadMore}
        itemContent={itemContent}
      />*/
