'use client';
import { useQueryString } from '@hooks/client';
import { useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';

import { DropListSort } from '@/features/drop-list-sort';
import { Typography } from '@/shared/ui';
import { InputAuth } from '@/shared/ui/desktop/input-auth';

import styles from './header.module.scss';

export const FavoritesHeader = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const param = searchParams.get('favoriteSearch');
  const [searchValue, setSearchValue] = useState('');
  const { createQueryString } = useQueryString();

  const setSearchParams = useDebounceCallback(
    useCallback(
      (value: string) =>
        router.push(
          createQueryString({
            pathname: '/favorite',
            name: 'favoriteSearch',
            value: value,
            asMultipleParams: false,
          }),
        ),
      [],
    ),
    500,
  );

  useEffect(() => {
    if (param) {
      setSearchValue(param);
    }
  }, []);

  useEffect(() => {
    setSearchParams(searchValue);
  }, [searchValue]);

  return (
    <div className={styles['content_header']}>
      <div className={styles['input_container']}>
        <InputAuth
          withErrorMessage={false}
          size={'s'}
          iconName={'magnifer-rounded'}
          value={searchValue}
          onChange={event => setSearchValue(event.currentTarget.value)}
          placeholder="Name, properties, rarity..."
        />
      </div>
      <div className={styles['sort_container']}>
        <Typography
          className={styles['sort_container-title']}
          weight="semi-bold"
        >
          Sorting:
        </Typography>
        <DropListSort
          variant={'favorite'}
          handleSelectSortType={item =>
            router.push(
              createQueryString({
                pathname: '/favorite',
                name: 'sort',
                value: item.filter,
                asMultipleParams: false,
              }),
            )
          }
        />
      </div>
    </div>
  );
};
