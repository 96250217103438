'use client';
import {
  CATEGORY_FILTERS,
  PROPERTIES_FILTERS,
  RARITY_FILTERS,
} from '@features/desktop/filter-shop-items/ui/constants';
import { useQueryString } from '@hooks/client';
import { observer } from 'mobx-react-lite';

import { FilterItemEntity } from '@/entities/desktop/filter-item/ui';
import { Divider } from '@/shared/ui';

import styles from './filter.module.scss';

export const FavoritesFilter = observer(() => {
  const { createQueryString, params } = useQueryString();

  return (
    <div className={styles['filters_container']}>
      <FilterItemEntity
        filters={CATEGORY_FILTERS}
        label="Categories"
        setValue={filter => {
          const newUrl = createQueryString({
            pathname: '/favorite',
            name: 'category',
            value: filter.filter,
            asMultipleParams: true,
          });

          window.history.pushState({}, '', newUrl);
        }}
        selectedValues={params['category']}
      />
      <Divider direction="horizontal" />
      <FilterItemEntity
        filters={PROPERTIES_FILTERS}
        label="Properties"
        setValue={filter => {
          const newUrl = createQueryString({
            pathname: '/favorite',
            name: 'property',
            value: filter.filter,
            asMultipleParams: true,
          });

          window.history.pushState({}, '', newUrl);
        }}
        selectedValues={params['property']}
      />
      <Divider direction="horizontal" />
      <FilterItemEntity
        filters={RARITY_FILTERS}
        label="Rarity"
        setValue={filter => {
          const newUrl = createQueryString({
            pathname: '/favorite',
            name: 'rarity',
            value: filter.filter,
            asMultipleParams: true,
          });

          window.history.pushState({}, '', newUrl);
        }}
        selectedValues={params['rarity']}
      />
    </div>
  );
});
