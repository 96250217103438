import { useQueryString } from '@hooks/client';
import { useFavorite } from '@hooks/queries/use-query-favorite';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';

import styles from './styles.module.scss';

export const AddToFavoriteItemCardFeature = observer(
  ({ realName }: { realName: string }) => {
    const { mappedParams } = useQueryString();
    const { items, addToFavorite, deleteItem } = useFavorite(mappedParams);
    const active = items.find(item => item.realName === realName);

    if (!realName) return;

    return (
      <Button
        as={motion.button}
        whileTap={{ scale: 0.98 }}
        variant={'secondary'}
        className={styles.container}
        iconLeft={
          <Illustration
            spanTagClassName={styles['icon']}
            spriteName={'icons'}
            name={active ? 'heart-broken' : 'heart'}
            style={{ color: '#6941C6' }}
          />
        }
        onClick={() =>
          !active ? addToFavorite(realName) : deleteItem(realName)
        }
        text={active ? 'Remove from favorite' : 'Add to favorite'}
      />
    );
  },
);
