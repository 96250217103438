import { ProfileHistoryTableData } from '@widgets/desktop/profile-history/ui/profile-history.types';
import {
  TableColumns,
  TablePurchasesRows,
  TableTopUpRows,
  TableWithdrawRows,
} from '@widgets/desktop/profile-history/ui/profile-history.types';
import { sample, sampleSize } from 'lodash';

import { inventoryItems } from '@/mocks/inventory-items';
import { mockedData } from '@/mocks/items';

export const tablePurchasesCols: TableColumns = {
  type: {
    title: 'Type',
  },
  item: {
    title: 'Item',
  },
  properties: {
    title: 'Properties',
  },
  price: {
    title: 'Price',
  },
  id: {
    title: 'ID',
  },
  date: {
    title: 'Date',
  },
};

export const tableWithdrawCols: TableColumns = {
  nickname: {
    title: 'Nickname',
  },
  status: {
    title: 'Status',
  },
  item: {
    title: 'Item',
  },
  properties: {
    title: 'Properties',
  },
  price: {
    title: 'Price',
  },
  id: {
    title: 'ID',
  },
  date: {
    title: 'Date',
  },
};

export const tableTopUpCols: TableColumns = {
  status: {
    title: 'Status',
  },
  method: {
    title: 'Method',
  },
  total: {
    title: 'Total',
  },
  id: {
    title: 'ID',
  },
  date: {
    title: 'Date',
  },
};

export const tablePurchasesRows: TablePurchasesRows[] =
  generateLargeDataset(); /*[
  {
    id:  634563 ,
    date: '04.03.24',
    price: '2.34',
    type: 'Pet',
    properties: ['neon'],
    item: {
      item: 'buffalo',
      rarity: 'rare',
      age: 'pre-teen',
      name: 'Zombie Buffalo'
    }
  },
];*/

export const tableWithdrawRows: TableWithdrawRows[] = [
  {
    item: {
      imageUri:
        'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/royal_egg_218155608038015716861250016536746955046.png',
      rare: 'legendary',
      game: 'mm2',
      name: 'Royal Egg',
      age: null,
      type: 'knife',
    },
    properties: {
      flyable: null,
      rideable: null,
      pumping: 'neon',
    },
    id: 27271,
    price: 3.22,
    status: {
      text: 'Ready for withdraw',
      code: 2,
    },
    date: '04.03.24',
    nickname: 'Aladin',
  },
];

export const tableTopUpRows: TableTopUpRows[] = [
  {
    id: 634563,
    date: '04.03.24',
    status: {
      text: 'Pending',
      code: 1,
    },
    method: 'Card',
    total: '2.34',
    game: 'adopt',
  },
];

function generateLargeDataset(): TablePurchasesRows[] {
  const items: TablePurchasesRows[] = [];

  for (let i = 0; i < 100000; i++) {
    const index = Math.floor(Math.random() * inventoryItems.length);
    const itemInfo = inventoryItems[index];
    const item = {
      id: itemInfo.id,
      price: itemInfo.price ?? 0,
      type: itemInfo.type,
      properties: {
        flyable: itemInfo.flyable,
        rideable: itemInfo.rideable,
        pumping: itemInfo.pumping,
      },
      item: {
        imageUri: itemInfo.imageUri,
        name: itemInfo.name,
        rare: itemInfo.rare,
        game: itemInfo.game,
        age: itemInfo.age,
        type: itemInfo.type,
      },
      date: `04.03.${2024 + Math.floor(Math.random() * 365)}`,
    };
    items.push(item);
  }

  return items;
}

export const testData: ProfileHistoryTableData = {
  'top-up': {
    columns: tableTopUpCols,
    rows: tableTopUpRows,
  },

  withdraw: {
    columns: tableWithdrawCols,
    rows: tableWithdrawRows,
  },

  purchases: {
    columns: tablePurchasesCols,
    rows: tablePurchasesRows,
  },
};
