'use client';
import cn from 'clsx';
import { useRouter } from 'next/navigation';
import React, { ComponentProps } from 'react';

import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui';

import styles from './see-all-button.module.scss';

type SeeAllButtonProps = {
  href: string;
  variant?: ComponentProps<typeof Button>['variant'];
  className?: string;
  handleClick?: () => void;
  disabled?: boolean;
};

export const SeeAllButton = ({
  href,
  variant = 'quaternary',
  handleClick,
  className,
  disabled,
}: SeeAllButtonProps) => {
  const router = useRouter();

  return (
    <Button
      disabled={disabled}
      variant={variant}
      onClick={handleClick ? handleClick : () => router.push(href)}
      text={'See all'}
      className={cn(styles.container, className)}
      width={'content'}
      iconRight={
        <Illustration
          spanTagClassName={styles['icon']}
          name={'alt-arrow-down'}
          spriteName={'icons'}
        />
      }
    />
  );
};
