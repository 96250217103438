import { CardItemEntity } from '@entities/desktop/cards';
import cn from 'clsx';
import React, { ReactNode } from 'react';

import {
  DivElementType,
  GlobalItem,
  GlobalMM2Item,
} from '@/shared/types/common';
import { Skeleton } from '@/shared/ui';

import styles from './content.module.scss';

export type Items = {
  items: (GlobalItem | GlobalMM2Item)[];
};

export type ContentWidgetItemsBlockProps = DivElementType & {
  headerSlot?: ReactNode;
  emptySlot?: ReactNode;
  isLoading?: boolean;
  skeletonCount?: number;
} & Items;

export const ContentWidget = ({
  headerSlot,
  emptySlot,
  items,
  isLoading,
  skeletonCount = 10,
}: ContentWidgetItemsBlockProps) => {
  if (items.length === 0 && !isLoading && emptySlot) {
    return emptySlot;
  }

  return (
    <div className={styles['items-block']}>
      {headerSlot && <div className={styles['header']}>{headerSlot}</div>}
      <div className={styles['items']}>
        {!isLoading &&
          items?.map((item, idx) => {
            return (
              <CardItemEntity
                key={`${item?.realName}${idx}`}
                variant={'tertiary'}
                item={item}
              />
            );
          })}
        {isLoading &&
          Array.from({ length: skeletonCount }).map((_, idx) => (
            <Skeleton
              key={`skeleton-list-cards-${idx}`}
              className={cn(styles['card-skeleton'])}
            />
          ))}
      </div>
    </div>
  );
};
