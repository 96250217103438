import { FooterLinksList } from '@widgets/desktop';
import { FooterWidgetProps } from '@widgets/desktop/footer/ui/footer.types';
import {
  footerLinks,
  footerSocialLinks,
} from '@widgets/desktop/footer/ui/utils';
import cn from 'clsx';
import Link from 'next/link';

import { Divider, Illustration, Logo } from '@/shared/ui';
import { Button } from '@/shared/ui/button';
import { AllIcons } from '@/shared/ui/illustration/illustration';
import { Typography } from '@/shared/ui/typography';

import styles from './footer.module.scss';
export const FooterWidget = ({ className, ...props }: FooterWidgetProps) => {
  const footerClasses = cn(styles['footer'], className);

  //const pathname = usePathname();

  /*
  if (pathname.includes('not-found')) {
    return null;
  }
*/

  return (
    <footer className={footerClasses} {...props}>
      <div className={styles['content-container']}>
        <div className={styles['left-slot']}>
          <Logo className={styles.logo} />
          <Typography className={styles['descr']}>
            BloxWay is not affiliated, associated, or partnered with UpliftGames
            LLC and Roblox Corporation in any way. We are an independent skins
            marketplace site.
          </Typography>

          <Typography className={styles['descr']}>
            BENEFICIARY: CRYSTAL FUTURE OU
            <br /> REG. NUMBER: 14198230
            <br /> BENEFICIARY’S ADDRESS: HARJU MAAKOND, TALLINN, KESKLINNA
            LINNAOSA, PIKK TN 7-5, 10123
          </Typography>
          <Typography className={styles['rights']}>
            © 2025 BloxWay - largest roblox marketplace. All Rights Reserved.
          </Typography>
          <div className={styles['social-icons']}>
            {footerSocialLinks?.map(item => {
              return 'type' in item ? (
                <Divider
                  key={item.id}
                  style={{ height: 36 }}
                  direction={'vertical'}
                />
              ) : (
                <Button
                  href={item.href}
                  as={Link}
                  target="_blank"
                  key={item.id}
                  iconLeft={
                    <Illustration
                      style={{ color: item.color }}
                      size={18}
                      name={item.icon as keyof AllIcons}
                      spriteName={'icons'}
                    />
                  }
                  variant={'quaternary'}
                  className={styles['social-icon']}
                />
              );
            })}
          </div>
        </div>
        <div className={styles['right-slot']}>
          <div className={styles['categories']}>
            <Typography className={styles['title']}>Categories</Typography>
            <div className={styles['links-container']}>
              <div className={styles['links']}>
                <FooterLinksList links={footerLinks?.categories?.group_0} />
              </div>
              <div className={styles['links']}>
                <FooterLinksList links={footerLinks?.categories?.group_1} />
              </div>
            </div>
          </div>
          <div className={styles['account']}>
            <Typography className={styles['title']} weight={'semi-bold'}>
              My account
            </Typography>
            <div className={styles['links-container']}>
              <div className={styles['links']}>
                <FooterLinksList links={footerLinks?.account?.group_0} />
              </div>
              <div className={styles['links']}>
                <FooterLinksList links={footerLinks?.account?.group_1} />
              </div>
            </div>
          </div>
          <div className={styles['support']}>
            <Typography className={styles['title']} weight={'semi-bold'}>
              Support
            </Typography>
            <div className={styles['links-container']}>
              <div className={styles['links']}>
                <FooterLinksList links={footerLinks?.support?.group_1} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Illustration
        spanTagClassName={styles['footer-decoration']}
        name={'footer-decoration'}
      />
    </footer>
  );
};
