import { ItemAge } from '@/shared/types/common';

export const commonAges: { value: ItemAge; label: string }[] = [
  { value: 'newborn', label: 'Newborn' },
  { value: 'junior', label: 'Junior' },
  { value: 'pre_teen', label: 'Pre-Teen' },
  { value: 'teen', label: 'Teen' },
  { value: 'post_teen', label: 'Post-Teen' },
  { value: 'full_grown', label: 'Full-Grown' },
];
export const neonAges: { value: ItemAge; label: string }[] = [
  { value: 'reborn', label: 'Reborn' },
  { value: 'twinkle', label: 'Twinkle' },
  { value: 'sparkle', label: 'Sparkle' },
  { value: 'flare', label: 'Flare' },
  { value: 'sunshine', label: 'Sunshine' },
  { value: 'luminous', label: 'Luminous' },
];
