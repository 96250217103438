export type FooterLink = {
  id: number | string;
  href: string;
  text: string;
  icon: string;
  color: string;
  isCategory?: boolean;
};

export const footerLinks = {
  categories: {
    group_0: [
      {
        id: 0,
        href: 'Pets',
        text: 'Pets',
        icon: 'cat',
        color: '#FE9920',
        isCategory: true,
      },
      {
        id: 1,
        href: 'Eggs',
        text: 'Eggs',
        icon: 'egg',
        color: '#D60F86',
        isCategory: true,
      },
      {
        id: 2,
        href: 'Potions',
        text: 'Potions',
        icon: 'potion',
        color: '#4891FF',
        isCategory: true,
      },
      {
        id: 3,
        href: 'Transport',
        text: 'Transport',
        icon: 'bus',
        color: '#6941C6',
        isCategory: true,
      },
    ],
    group_1: [
      {
        id: 0,
        href: 'New',
        text: 'New',
        icon: 'lightning',
        color: '#40BB18',
        isCategory: true,
      },
      {
        id: 1,
        href: 'Exclusive',
        isCategory: true,
        text: 'Exclusive',
        icon: 'star',
        color: '#303030',
      },
      {
        id: 2,
        href: 'Discount',
        isCategory: true,
        text: 'Discounted items',
        icon: 'ticket-sale',
        color: '#FE9920',
      },
      {
        id: 3,
        href: '/categories',
        text: 'All',
        icon: 'widget',
        color: '#6941C6',
      },
    ],
  },
  account: {
    group_0: [
      {
        id: 0,
        href: '/inventory',
        text: 'Inventory',
        icon: 'bag',
        color: '#40BB18',
      },
      /*{
        id: 1,
        href: '/profile/settings',
        text: 'Settings',
        icon: 'settings',
        color: '#6941C6',
      },*/
      {
        id: 2,
        href: '#',
        text: 'Support',
        icon: 'headphones',
        color: '#6941C6',
      },
      {
        id: 3,
        href: '/cart',
        text: 'Cart',
        icon: 'cart',
        color: '#6941C6',
      },
      {
        id: 1,
        href: '/favorite',
        text: 'Wishlist',
        icon: 'heart',
        color: '#EB3C3C',
      },
    ],
    group_1: [
      {
        id: 1,
        href: '/profile/account',
        text: 'Profile',
        icon: 'user',
        color: '#6941C6',
      },
      {
        id: 2,
        href: '/profile/history',
        text: 'History',
        icon: 'clock',
        color: '#6941C6',
      },
      {
        id: 3,
        href: '/faq',
        text: 'FAQ',
        icon: 'question-square',
        color: '#6941C6',
      },
    ],
  },
  support: {
    group_0: [
      {
        id: 0,
        href: '#',
        text: 'Live-Chat',
        icon: 'headphones',
        color: '#6941C6',
      },
    ],
    group_1: [
      {
        id: 0,
        href: '/policy/aml',
        text: 'AML KYC',
        icon: 'aml-kyc',
        color: '#6941C6',
      },
      {
        id: 1,
        href: '/contacts',
        text: 'Contacts',
        icon: 'passport',
        color: '#FE9920',
      },
      {
        id: 2,
        href: '/policy/privacy',
        text: 'Privacy Policy',
        icon: 'notebook-footer',
        color: '#4891FF',
      },
      {
        id: 3,
        href: '/policy/user',
        text: 'User Agreement',
        icon: 'user-check-rounded',
        color: '#40BB18',
      },
    ],
  },
};

export const footerSocialLinks = [
  /* { id: 'social-link-0', icon: 'vk', color: '#9E9D9E', href: '#' },*/
  {
    id: 'social-link-1',
    icon: 'instagram',
    color: '#9E9D9E',
    href: 'https://www.instagram.com/bloxwaygg',
  },
  {
    id: 'social-link-2',
    icon: 'telegram',
    color: '#9E9D9E',
    href: 'https://t.me/BloxWayGG',
  },
  /* { id: 'social-link-3', icon: 'youtube', color: '#9E9D9E', href: '#' },*/
  {
    id: 'social-link-4',
    icon: 'tik-tok',
    color: '#9E9D9E',
    href: 'https://www.tiktok.com/@bloxwaygg',
  },
  {
    id: 'social-link-5',
    icon: 'discord',
    color: '#9E9D9E',
    href: 'https://discord.gg/kkPgEN5HsX',
  },
  /* { id: 'social-link-6', type: 'divider' },
  {
    id: 'social-link-7',
    icon: 'user-check-rounded',
    color: '#6941C6',
    href: '#',
  },
  {
    id: 'social-link-8',
    icon: 'notebook-footer',
    color: '#6941C6',
    href: '#',
  },*/
];
