import { getCookie } from 'cookies-next';
import Axios from 'xior';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: 'https://market.shipblox.com/api',
  headers: {
    Origin: 'bloxway.gg',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

type CustomClient<T> = (data: {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  params?: Record<string, any>;
  headers?: Record<string, any>;
  data?: BodyType<unknown>;
  signal?: AbortSignal;
}) => Promise<T>;

export const useCustomClient = <T>(): CustomClient<T> => {
  return async ({ url, method, params, data, signal }) => {
    const response = await AXIOS_INSTANCE.request<T>({
      url,
      method,
      params,
      data,
      signal,
      headers: {
        ...data?.headers,
        Authorization: `Bearer ${getCookie('jwt')}`,
      },
    });

    return response.data;
  };
};

export default useCustomClient;

export type ErrorType<ErrorData> = ErrorData;

export type BodyType<BodyData> = BodyData & { headers?: any };
