'use client';

import cn from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import React, { useState } from 'react';

import { Question as QuestionType } from '@/app/[locale]/faq/mocks';
import { Illustration, Typography } from '@/shared/ui';

import styles from './question.module.scss';

interface QuestionProps {
  question: QuestionType;
}

export const Question = ({ question: { title, text, img } }: QuestionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <motion.div
      onTap={() => setIsOpen(prev => !prev)}
      className={styles['question-wrapper']}
    >
      <div
        className={cn(styles.question, {
          [styles.active]: isOpen,
        })}
      >
        <Illustration
          name={isOpen ? 'square-stepper-minus' : 'square-stepper-plus'}
          spriteName={'icons'}
          spanTagClassName={styles.icon}
        />
        <Typography className={styles.title}>{title}</Typography>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.35 },
              height: 'max-content',
            }}
            exit={{ opacity: 0, height: 0 }}
            className={styles['question-content']}
          >
            {img && (
              <div className={styles['img-container']}>
                <Image
                  className={styles.img}
                  loading={'eager'}
                  priority={true}
                  src={img}
                  fill
                  sizes={''}
                  quality={100}
                  alt={'faq-img'}
                  style={{ borderRadius: 'inherit', objectFit: 'cover' }}
                />
              </div>
            )}
            <Typography className={styles.subtitle}>{text}</Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};
