'use client';
import {
  usePutCartsSelectIdHook,
  usePutCartsUnselectIdHook,
} from '@api-product/🧺-корзина-🔓/🧺-корзина-🔓';
import { CartItemsResponse } from '@hooks/queries/common.types';
import { QueryKeys } from '@hooks/queries/queryKeys';
import { useCart } from '@hooks/queries/use-query-cart';
import { useQueryClient } from '@tanstack/react-query';
import { Game } from '@utils/AppConfig';
import { toggleSelected } from '@utils/cart';
import { formatAge } from '@utils/helpers';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import {
  ItemMM2RarityVariants,
  ItemRarityVariants,
  ProductById,
} from '@/shared/types/common';
import {
  Item,
  ItemRarityCard,
  Skeleton,
  TagCategory,
  TagQuantity,
  TagRarity,
  Typography,
} from '@/shared/ui';
import Checkbox from '@/shared/ui/desktop/check-box/check-box';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';
import { useStore } from '@/store/context';

import styles from './card-shopping-item.module.scss';
import { CardShoppingItemEntityProps } from './card-shopping-item.types';
export const CardShoppingItemEntityMobile = ({
  className,
  bottomRight,
  bottomLeft,
  showPrice = false,
  variant = 'primary',
  item,
  disabled,
  size,
  loading,
  isSoldout,
  ...props
}: CardShoppingItemEntityProps) => {
  const cardShoppingItemClasses = cn(
    styles['container'],
    { [styles['without-bottom-slots']]: !bottomRight && !bottomLeft },
    { [styles['disabled']]: disabled },
    { [styles[`size--${size}`]]: size },
    className,
  );

  const { toggleSelected } = useCart();

  const getPricesGroups = (item: ProductById) => {
    const priceCounts: { [key: number]: number } = {};

    if (!item.prices) return [{ count: item.count, price: null }];

    item.prices?.slice(0, item.count).forEach(price => {
      priceCounts[price] = (priceCounts[price] || 0) + 1;
    });

    return Object.entries(priceCounts).map(([price, count]) => ({
      price: Number(price),
      count: count,
    }));
  };

  return (
    <article className={cardShoppingItemClasses} {...props}>
      <div
        className={cn(styles['top-slot'], {
          [styles['with-bottom-slot']]: bottomRight || bottomLeft,
        })}
      >
        {item.prices && (
          <Checkbox
            checked={item.selected}
            onChange={() => toggleSelected(item)}
          />
        )}
        <div className={styles['items']}>
          {getPricesGroups(item).map((price, index) => (
            <div className={styles['top-slot__left']} key={`${price}-${index}`}>
              <CardShoppingItemEntityMobile.ItemImageSlot
                src={item?.imageUri}
                rare={item?.rare}
                game={item.game}
                count={price.count}
                isSoldout={isSoldout}
              />

              <div
                className={cn(styles['item-info'], {
                  [styles['secondary']]: variant === 'secondary',
                })}
              >
                {variant === 'primary' && showPrice && (
                  <>
                    <Typography className={styles['title']}>
                      {item.name}
                    </Typography>
                    {item.age && (
                      <Typography
                        style={{ textTransform: 'capitalize' }}
                        className={styles['age']}
                      >
                        {formatAge(item.age)}
                      </Typography>
                    )}
                    <CardShoppingItemEntityMobile.ItemPrice
                      variant={'primary'}
                      price={price.price ?? 0}
                      status={null}
                    />
                  </>
                )}

                {variant === 'secondary' && (
                  <>
                    <CardShoppingItemEntityMobile.ItemPrice
                      variant={'primary'}
                      price={price.price ?? 0}
                      status={null}
                    />
                    <Typography
                      className={cn(styles['title'], styles['secondary'])}
                    >
                      {item.name}
                    </Typography>
                  </>
                )}
              </div>
              <div className={styles['top-slot__right']}>
                <CardShoppingItemEntityMobile.ItemProperties product={item} />
              </div>
            </div>
          ))}
        </div>
      </div>
      {variant === 'primary' && (bottomLeft || bottomRight) && (
        <div className={styles['bottom-slot']}>
          {bottomLeft && (
            <div className={styles['bottom-slot__left']}>{bottomLeft}</div>
          )}
          {bottomRight && (
            <div className={styles['bottom-slot__right']}>{bottomRight}</div>
          )}
        </div>
      )}
    </article>
  );
};

CardShoppingItemEntityMobile.ItemImageSlot = ({
  game,
  src,
  rare,
  count,
  isSoldout,
}: {
  game: Game;
  src: string;
  rare: ItemRarityVariants | ItemMM2RarityVariants;
  count: number;
  isSoldout?: boolean;
}) => {
  const colorScheme = game === 'mm2' ? colorsMM2Variant : colorsByVariant;

  return (
    <div
      className={cn(styles['item'], {
        [styles['item-unavailable']]: isSoldout,
      })}
    >
      <ItemRarityCard
        colorScheme={colorScheme}
        className={styles['background']}
        size={'53'}
        variant={rare}
      />
      <Item className={styles['item-variant']} size={'48'} src={src} />
      {isSoldout ? (
        <div className={styles['soldout-label']} />
      ) : (
        <TagQuantity quantity={count} className={styles['item-count']} />
      )}
    </div>
  );
};

CardShoppingItemEntityMobile.ItemProperties = ({
  product,
}: {
  product: ProductById;
}) => {
  return (
    <div className={styles['properties']}>
      <TagRarity variant={product.rare} className={styles['rarity']} />
      {product.flyable && (
        <TagCategory variant={'fly'} className={styles['category']} />
      )}
      {product.rideable && (
        <TagCategory variant={'ride'} className={styles['category']} />
      )}
    </div>
  );
};

CardShoppingItemEntityMobile.ItemPrice = observer(
  ({
    price,
    status,
    variant = 'primary',
  }: { price: number; status: 'unavailable' | null } & {
    variant: CardShoppingItemEntityProps['variant'];
  }) => {
    const currencySign = useStore().app.getCurrencySign;

    if (status === 'unavailable') {
      return (
        <div className={styles['price']}>
          <Typography className={styles['status']}>Not available</Typography>
        </div>
      );
    }

    return (
      <div className={cn(styles['price'], styles[variant])}>
        <Typography className={cn(styles['current-price'], styles[variant])}>
          {currencySign}
          {price}
        </Typography>
        {Boolean(price) && (
          <Typography
            decoration={'line-through'}
            className={cn(styles['old-price'], styles[variant])}
          >
            {currencySign}
            {price}
          </Typography>
        )}
      </div>
    );
  },
);
