'use client';
import { CardItemEntity } from '@entities/desktop/cards';
import { ProductsQuery } from '@hooks/queries/common.types';
import { useInfiniteProducts } from '@hooks/queries/use-infinite-query-products';
import { useProducts } from '@hooks/queries/use-query-products';
import { ListCardsWidget, ListCardsWidgetVirtualized } from '@widgets/desktop';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import React from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { SeeAllButton } from '@/shared/components/see-all-button';
import { Label } from '@/shared/ui';
import { useStore } from '@/store/context';

export const HOT_SALE_QUERY_KEY = 'hot-sales-products' as const;

export const getHotSalesQueryFilters = (pageParam: number) => {
  const query: ProductsQuery = {
    filter: {
      search: '',
      types: [
        {
          type: 'pet',
          rarity: ['uncommon', 'legendary', 'ultra_rare', 'rare', 'common'],
          levels: ['default', 'mega_neon', 'neon'],
          ages: [
            'newborn',
            'junior',
            'pre_teen',
            'teen',
            'post_teen',
            'reborn',
            'full_grown',
            'twinkle',
            'sparkle',
            'flare',
            'sunshine',
            'luminous',
          ],
        },
        {
          type: 'egg',
          rarity: ['uncommon', 'legendary', 'ultra_rare', 'rare', 'common'],
        },
        {
          type: 'potion',
          rarity: ['uncommon', 'legendary', 'ultra_rare', 'rare', 'common'],
        },
        {
          type: 'transport',
          rarity: ['uncommon', 'legendary', 'ultra_rare', 'rare', 'common'],
          subType: [
            'scooters',
            'skateboards',
            'legendary_gift_vehicles',
            'snowboards',
            'event_vehicles',
            'vehicle_dealership',
            'premium_vehicles',
            'star_rewards_vehicles',
          ],
        },
      ],
      onlyHotSales: true,
      onlyNew: false,
      onlyExclusive: false,
      onlyDiscount: false,
    },
    order: {
      by: 'price',
      direction: 'asc',
    },
    page: 0,
    limit: 16,
  };

  return query;
};

export const HotSalesItems = observer(() => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const { isLoading, items, isFetching } = useProducts({
    getQueryFiltersFn: getHotSalesQueryFilters,
    uniqueQueryKey: HOT_SALE_QUERY_KEY,
  });

  //@todo виртуализация

  const isLargeScreen = useMediaQuery('(min-width: 1920px)');
  const isMediumScreen = useMediaQuery('(min-width: 1440px)');
  const isSmallScreen = useMediaQuery('(min-width: 1024px)');

  const slicesItems = isLargeScreen
    ? items
    : isMediumScreen
      ? items.slice(0, 14)
      : items.slice(0, 12);

  const game = useStore()?.app?.selectedGame;

  return (
    <ListCardsWidget
      cardsVariant={'primary'}
      topSlot={
        <>
          <Label
            labelVariant={'yellow'}
            icon={'fire-square-red'}
            variant={'primary'}
          >
            Hot Sales
          </Label>
          <SeeAllButton href={'/shop?sale=hot-sale'} />
        </>
      }
      isLoading={isFetching}
      bottomSlot={
        <>
          {slicesItems.map((item, index) => (
            <CardItemEntity
              game={game}
              applyHandleClickOnFullCard
              isMM2Variant={false}
              tagsInfo={[{ text: 'Hot Price', variant: 'hot-price' }]}
              variant={'primary'}
              key={`card-${index}-${item.realName}-${item?.name}`}
              item={item}
            />
          ))}
          {/*{(!isFetching) && <div ref={loadMoreRef} ></div>}*/}
        </>
      }
    />

    /*    <ListCardsWidgetVirtualized
      isLoading={isLoading}
      isFetching={isFetching}
      cardsVariant={'primary'}
      topSlot={
        <>
          <Label
            labelVariant={'yellow'}
            icon={'fire-square-red'}
            variant={'primary'}>
            Hot Sales
          </Label>
          <SeeAllButton href={'/shop?sort=popular'} />
        </>
      }

      items={items}
      loadMore={() => fetchNextPage()}
      itemContent={(index, item) => (
        <CardItemEntity
          key={`${item.id}-${index}`}
          applyHandleClickOnFullCard
          isMM2Variant={false}
          variant={'primary'}
          item={item}
        />
      )}
    />*/
  );
});
