'use client';
import { AddToCartFeature } from '@features/desktop/add-to-cart';
import { AddToFavoriteFeature } from '@features/desktop/add-to-favorite/ui';
import { SelectPetPropertiesFeature } from '@features/select-pet-properties';
import { sortProperties } from '@features/select-pet-properties/ui/select-pet-properties';
import { useQueryString } from '@hooks/client';
import { formatAge } from '@utils/helpers';
import cn from 'clsx';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';

import { ItemRarityCard } from '@/shared/ui';
import { CardItemInfo, CardItemTop } from '@/shared/ui/cards';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';

import cardItemInfoStyles from './card-item-info.module.scss';
import styles from './card-item-shop.module.scss';
import { CardItemShopEntityProps } from './card-item-shop.types';
import cardItemTopStyles from './card-item-top.module.scss';

export const CardItemShopEntityMobile = ({
  children,
  className,
  item,
  game,
  onClick = () => {},
  ...props
}: CardItemShopEntityProps) => {
  const { mappedParams } = useQueryString();

  const sorting = mappedParams.has('sort') && mappedParams.get('sort')[0];

  const defaultSelectedCard =
    item?.type === 'pet'
      ? sortProperties(item.products, sorting === 'cheaper' ? 'asc' : 'desc')[0]
      : item.products[0];
  const [selectedCard, setSelectedCard] = useState(defaultSelectedCard);

  const router = useRouter();

  const handleClick = () => {
    router.push(`/shop/${item.type}/${item?.realName}/${selectedCard.id}`);
  };

  const cardItemShopClasses = cn(styles['card-item-shop'], className);
  const colorScheme = game === 'mm2' ? colorsMM2Variant : colorsByVariant;

  return (
    <article className={cardItemShopClasses} {...props}>
      <CardItemTop
        onClick={handleClick}
        customStyles={cardItemTopStyles}
        // tagsInfo={tagsInfo}
        src={item.img}
        topRightSlot={
          <AddToFavoriteFeature
            variant={'primary'}
            realName={item.realName}
            isMobile
          />
        }
        backgroundIllustration={
          <div
            className={styles['item-rarity-card-wrapper']}
            style={{ position: 'absolute' }}
          >
            {/*<div id={'soldout-label'} className={cn(styles['soldout-label'])} />*/}
            <ItemRarityCard
              colorScheme={colorScheme}
              className={styles['item-rarity-card']}
              size={'100'}
              variant={item.rare}
            />
          </div>
        }
      />

      <CardItemInfo
        onTitleClick={onClick}
        customStyles={cardItemInfoStyles}
        info={{
          title: item?.name,
          subtitle: formatAge(selectedCard.age ?? item.type),
          price: {
            old: selectedCard.price ?? 0,
            current: selectedCard.price ?? 0,
          },
        }}
      />
      <div
        onClick={() =>
          router.push(`/shop/${item.type}/${item?.realName}/${selectedCard.id}`)
        }
        className={styles['properties-slot']}
      >
        <SelectPetPropertiesFeature
          itemType={item.type}
          products={sortProperties(
            item.products,
            sorting === 'cheaper' ? 'asc' : 'desc',
          )}
          onTabChange={product => setSelectedCard(product)}
          href={`/shop/${item.type}/${item?.realName}/${selectedCard.id}`}
        />
      </div>

      <div className={styles['bottom-slot']}>
        <AddToCartFeature
          id={selectedCard.id}
          prices={selectedCard.prices}
          price={selectedCard.price!}
          name={item.realName}
          selected
        />
      </div>
    </article>
  );
};

//@TODO попробовать передавать CardItemTop через слот topSlot напр,
// а то 10 пропсов для сущности это овер (тоже самое в CardItem для десктопа)
