import { it } from 'node:test';

import { makeAutoObservable } from 'mobx';

import { inventoryItems } from '@/mocks/inventory-items';

export const itemsWithdrawalSteps = {
  SELECT_ACCOUNT: 'selectAccount',
  SELLER_VERIFICATION: 'sellerVerification',
  INVITE_SELLER_AS_FRIEND: 'inviteSellerAsFriend',
  WAITING_SELLER_ACCOUNT: 'waitingSellerAccount',
  PICK_UP_ITEMS: 'pickUpItems',
  ERROR: 'withdrawalError',
  SUCCESS: 'withdrawalSuccess',
} as const;

type Keys = keyof typeof itemsWithdrawalSteps;
type AvailableForms = (typeof itemsWithdrawalSteps)[Keys] | null;

class ItemsWithdrawalStore {
  cards = inventoryItems;
  selectedIds: number[] = [];
  openedForm: AvailableForms = null;
  step: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  clearCards() {
    this.cards = [];
  }

  setTimesUp(itemId: number) {
    if (this.selectedIds.includes(itemId)) {
      this.selectedIds = this.selectedIds.filter(
        currentId => currentId !== itemId,
      );
    }
    this.cards.forEach(card => {
      if (card.id === itemId) {
        card.timesUp = true;
      }
    });
  }

  toggleSelected(itemId: number) {
    if (this.selectedIds.includes(itemId)) {
      this.selectedIds = this.selectedIds.filter(
        currentId => currentId !== itemId,
      );
    } else if (this.selectedIds.length < 4) {
      this.selectedIds = [itemId, ...this.selectedIds];
    }
  }

  selectAll() {
    const sortedItems = this.cards
      .filter(card => !card.timesUp)
      .sort((card1, card2) => {
        const card1Timer =
          typeof card1.timer !== 'number'
            ? parseInt(card1.timer) * 86400
            : card1.timer;
        const card2Timer =
          typeof card2.timer !== 'number'
            ? parseInt(card2.timer) * 86400
            : card2.timer;

        return card2Timer - card1Timer;
      });

    this.selectedIds = sortedItems.slice(-4).map(item => item.id);
  }

  deselect() {
    this.selectedIds = [];
  }

  open() {
    this.openedForm = itemsWithdrawalSteps.SELECT_ACCOUNT;
  }

  close() {
    this.openedForm = null;
  }

  setNextStep(value: AvailableForms) {
    this.openedForm = value;
  }
}

export const itemsWithdrawalStore = new ItemsWithdrawalStore();
