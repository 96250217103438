'use client';
import { usePostCartsAddHook } from '@api-product/🧺-корзина-🔓/🧺-корзина-🔓';
import { QueryKeys } from '@hooks/queries/queryKeys';
import { useCart } from '@hooks/queries/use-query-cart';
import { useQueryClient } from '@tanstack/react-query';
import React, { MouseEvent, useMemo } from 'react';

import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';

import styles from './price-card-btn.module.scss';
type AddToCartPriceCardFeatureProps = {
  className?: string;
  itemId: number;
  disabled?: boolean;
  prices: number[];
  price: number;
};

export const AddToCartPriceCardFeature = ({
  itemId,
  prices,
  price,
  disabled,
}: AddToCartPriceCardFeatureProps) => {
  const { addToCart } = useCart();

  const handleActionButtonClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => {
    if (itemId) {
      e?.stopPropagation();
      addToCart({
        id: itemId,
        count: 1,
        prices: prices!,
        selected: true,
        price: price,
      });
    }
  };

  return (
    <Button
      disabled={disabled}
      className={styles['action-btn']}
      text="Add to cart"
      variant="primary"
      onClick={handleActionButtonClick}
      iconLeft={
        <Illustration
          name={'cart-plus'}
          spriteName={'icons'}
          id={'btn-icon'}
          style={{ color: '#FDFDFD' }}
        />
      }
    />
  );
};
