import { CardCategoryProps } from '@entities/card-category/ui/card-category.types';

import { ItemRarityVariants } from '@/shared/types/common';
import { CategoryItemVariant, ItemVariant } from '@/shared/ui/item/item.types';
import { ItemRarityCategoryCardVariant } from '@/shared/ui/item-rarity-category-card/item-rarity-category-card.types';

export type CategoryItem = {
  id: string;
  categoryPath: string;
  item: CategoryItemVariant;
  title: string;
  subtitle?: string;
  rare: ItemRarityCategoryCardVariant;
};

export const mockedCategories: CategoryItem[] = [
  {
    id: 'random-category-4',
    categoryPath: 'pets',
    item: 'pets',
    title: 'Pets',
    subtitle: 'Go to category',
    rare: 'orange',
  },
  {
    id: 'random-category-0',
    categoryPath: 'eggs',
    item: 'eggs',
    title: 'Eggs',
    subtitle: 'Go to category',
    rare: 'pink',
  },
  {
    id: 'random-category-2',
    categoryPath: 'potions',
    item: 'potions',
    title: 'Potions',
    subtitle: 'Go to category',
    rare: 'blue',
  },
  {
    id: 'random-category-3',
    categoryPath: 'new',
    item: 'new',
    title: 'New',
    subtitle: 'Go to category',
    rare: 'green',
  },
  {
    id: 'random-category-5',
    categoryPath: 'exclusive',
    item: 'exclusive',
    title: 'Exclusive',
    subtitle: 'Go to category',
    rare: 'black',
  },
  {
    id: 'random-category-6',
    categoryPath: 'transport',
    item: 'transport',
    title: 'Transport',
    subtitle: 'Go to category',
    rare: 'purple',
  },
];

export const mockedMM2Categories: CategoryItem[] = [
  {
    id: 'random-category-4',
    categoryPath: 'mm2-knives',
    item: 'mm2-knives',
    title: 'Knives',
    subtitle: 'Go to category',
    rare: 'blue',
  },
  {
    id: 'random-category-0',
    categoryPath: 'mm2-guns',
    item: 'mm2-guns',
    title: 'Guns',
    subtitle: 'Go to category',
    rare: 'green',
  },
  {
    id: 'random-category-2',
    categoryPath: 'mm2-pets',
    item: 'mm2-pets',
    title: 'Pets',
    subtitle: 'Go to category',
    rare: 'orange',
  },
  {
    id: 'random-category-3',
    categoryPath: 'mm2-bundles',
    item: 'mm2-bundles',
    title: 'Bundles',
    subtitle: 'Go to category',
    rare: 'purple',
  },
  {
    id: 'random-category-5',
    categoryPath: 'mm2-new',
    item: 'mm2-new',
    title: 'New',
    subtitle: 'Go to category',
    rare: 'green',
  },
  {
    id: 'random-category-6',
    categoryPath: 'mm2-exclusive',
    item: 'mm2-exclusive',
    title: 'Exclusive',
    subtitle: 'Go to category',
    rare: 'black',
  },
];

export const mockedCategoriesMobile1: CategoryItem[] = [
  {
    id: 'random-category-9',
    categoryPath: 'pets',
    item: 'pets',
    title: 'Pets',
    subtitle: 'Go to category',
    rare: 'orange',
  },
  {
    id: 'random-category-10',
    categoryPath: 'eggs',
    item: 'eggs',
    title: 'Eggs',
    subtitle: 'Go to category',
    rare: 'pink',
  },
  {
    id: 'random-category-11',
    categoryPath: 'potions',
    item: 'potions',
    title: 'Potions',
    subtitle: 'Go to category',
    rare: 'blue',
  },
  {
    id: 'random-category-7',
    categoryPath: 'new',
    item: 'new',
    title: 'New',
    subtitle: 'Go to category',
    rare: 'green',
  },
  {
    id: 'random-category-8',
    categoryPath: 'exclusive',
    item: 'exclusive',
    title: 'Exclusive',
    subtitle: 'Go to category',
    rare: 'black',
  },
  {
    id: 'random-category-12',
    categoryPath: 'transport',
    item: 'transport',
    title: 'Transport',
    subtitle: 'Go to category',
    rare: 'purple',
  },
];

export type MobileCategoryItem = Omit<CategoryItem, 'subtitle' | 'rare'> & {
  icon: CardCategoryProps['icon'];
  variant: CardCategoryProps['variant'];
  iconColor?: string;
};

export const mockedCategoriesMobile2: MobileCategoryItem[] = [
  {
    id: 'random-category-17',
    categoryPath: 'new',
    item: 'new',
    iconColor: 'var(--global-green600)',
    title: 'New',
    icon: 'lightning',
    variant: 'green',
  },
  {
    id: 'random-category-18',
    categoryPath: 'exclusive',
    iconColor: 'var(--global-black100)',
    item: 'exclusive',
    title: 'Exclusive',
    icon: 'star',
    variant: 'black',
  },
  {
    id: 'random-category-13',
    categoryPath: 'pets',
    iconColor: 'var(--global-orange300)',
    item: 'pets',
    title: 'Pets',
    icon: 'cat',
    variant: 'orange',
  },
  {
    id: 'random-category-14',
    categoryPath: 'eggs',
    iconColor: 'var(--global-pink300)',
    item: 'eggs',
    title: 'Eggs',
    icon: 'egg',
    variant: 'pink',
  },
  {
    id: 'random-category-16',
    categoryPath: 'potions',
    iconColor: 'var(--global-blue300)',
    item: 'potions',
    title: 'Potions',
    icon: 'potion',
    variant: 'blue',
  },
  {
    id: 'random-category-15',
    categoryPath: 'transport',
    iconColor: 'var(--global-purple300)',
    item: 'transport',
    title: 'Transport',
    icon: 'bus',
    variant: 'purple',
  },
  {
    id: 'random-category-19',
    categoryPath: 'discount',
    item: 'discount',
    iconColor: 'var(--global-orange600)',
    title: 'Discount',
    icon: 'ticket-sale',
    variant: 'black',
  },
  {
    id: 'random-category-20',
    categoryPath: 'cheap',
    iconColor: 'var(--global-blue300)',
    item: 'under',
    title: 'Under $ 3.5',
    icon: 'tag',
    variant: 'black',
  },
  /* {
    id: 'random-category-21',
    categoryPath: 'x-mas',
    item: 'baku',
    title: 'X-MAS Sale',
    icon: 'cup-hot',
    variant: 'black'
  },*/
];
export const mockedMM2CategoriesMobile: MobileCategoryItem[] = [
  {
    id: 'random-category-4',
    categoryPath: 'mm2-knives',
    item: 'mm2-knives',
    title: 'Knives',
    icon: 'knife',
    iconColor: 'var(--global-blue600)',
    variant: 'blue',
  },
  {
    id: 'random-category-0',
    categoryPath: 'mm2-guns',
    item: 'mm2-guns',
    title: 'Guns',
    icon: 'gun',
    iconColor: 'var(--global-green600)',
    variant: 'green',
  },
  {
    id: 'random-category-2',
    categoryPath: 'mm2-pets',
    item: 'mm2-pets',
    title: 'Pets',
    icon: 'cat',
    iconColor: 'var(--global-orange300)',
    variant: 'orange',
  },
  {
    id: 'random-category-3',
    categoryPath: 'mm2-bundles',
    item: 'mm2-bundles',
    title: 'Bundles',
    icon: 'box',
    iconColor: 'var(--global-purple600)',
    variant: 'purple',
  },
  {
    id: 'random-category-5',
    categoryPath: 'mm2-new',
    item: 'mm2-new',
    title: 'New',
    icon: 'lightning',
    iconColor: 'var(--global-green600)',
    variant: 'green',
  },
  {
    id: 'random-category-6',
    categoryPath: 'mm2-exclusive',
    item: 'mm2-exclusive',
    title: 'Exclusive',
    icon: 'star',
    iconColor: 'var(--global-black300)',
    variant: 'black',
  },
  {
    id: 'random-category-19',
    categoryPath: 'mm2-discount',
    item: 'mm2-discount',
    iconColor: 'var(--global-orange600)',
    title: 'Discount',
    icon: 'ticket-sale',
    variant: 'orange',
  },
  {
    id: 'random-category-20',
    categoryPath: 'mm2-cheap',
    iconColor: 'var(--global-blue300)',
    item: 'mm2-under',
    title: 'Under $ 3.5',
    icon: 'tag',
    variant: 'blue',
  },
];
