'use client';
import { SidebarFavoritesEntity } from '@entities/sidebar';
import { useQueryString } from '@hooks/client';
import { ContentWidget } from '@widgets/desktop';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';

import { WithShopItems } from '@/shared/hocs';
import { useFavorite } from '@/shared/hooks/queries/use-query-favorite';
import { Illustration, Label } from '@/shared/ui';

import styles from './cards.module.scss';

export const FavoritesCards = observer(() => {
  const router = useRouter();
  const { mappedParams } = useQueryString();
  const { items, isLoading } = useFavorite(mappedParams);

  return (
    <>
      <ContentWidget
        items={items}
        isLoading={isLoading}
        emptySlot={
          <SidebarFavoritesEntity
            className={styles.empty}
            topSlot={
              <SidebarFavoritesEntity.Image variant={'favorite-no-items'} />
            }
            middleSlot={
              <SidebarFavoritesEntity.Info
                info={{
                  title: 'Favorites are empty',
                  subtitle:
                    'Add items using the heart️ button on the item card!',
                }}
              />
            }
            bottomSlot={
              <SidebarFavoritesEntity.ActionButton
                text={'Open categories'}
                onClick={() => router.push('/categories')}
                variant={'primary'}
                iconRight={
                  <Illustration
                    id={'sidebar-fav-icon'}
                    spriteName={'icons'}
                    name={'square-arrow-right'}
                  />
                }
                iconLeft={
                  <Illustration
                    id={'sidebar-fav-icon'}
                    spriteName={'icons'}
                    name={'widget'}
                  />
                }
              />
            }
          />
        }
      />
      <WithShopItems>
        {({ items }) => (
          <ContentWidget
            items={items}
            headerSlot={
              <Label
                labelVariant={'yellow'}
                icon={'graph-up'}
                variant={'primary'}
              >
                Popular Items
              </Label>
            }
          />
        )}
      </WithShopItems>
    </>
  );
});
