import cn from 'clsx';

import { Item, ItemRarityLiveFeed, TagCategory, Typography } from '@/shared/ui';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-live-feed/item-rarity-live-feed';

import styles from './card-drop-option.module.scss';
import {
  CardDropOptionEntityItemInfoProps,
  CardDropOptionEntityItemPropertiesProps,
  CardDropOptionEntityItemProps,
  CardDropOptionEntityProps,
} from './card-drop-option.types';
export const CardDropOptionEntity = ({
  leftSlot,
  middleSlot,
  rightSlot,
  className,
  ...props
}: CardDropOptionEntityProps) => {
  return (
    <article
      className={cn(styles['drop-option-container'], className)}
      {...props}
    >
      {leftSlot}
      {middleSlot}
      {rightSlot}
    </article>
  );
};

CardDropOptionEntity.Item = ({
  rare,
  src,
  game,
}: CardDropOptionEntityItemProps) => {
  const colorScheme = game === 'mm2' ? colorsMM2Variant : colorsByVariant;

  return (
    <div
      className={cn(styles['option-item-container'], styles['item-paddings'])}
    >
      <ItemRarityLiveFeed
        colorScheme={colorScheme}
        size={'58'}
        className={cn(styles['item-rarity'], styles['item-paddings'])}
        variant={rare}
      />
      <Item className={styles['item']} size={'152'} src={src} />
    </div>
  );
};

CardDropOptionEntity.ItemInfo = ({
  title,
  age,
}: CardDropOptionEntityItemInfoProps) => {
  return (
    <div className={styles['option-item-info-container']}>
      {title && <Typography className={styles['title']}>{title}</Typography>}
      {age && <Typography className={styles['age']}>{age}</Typography>}
    </div>
  );
};

CardDropOptionEntity.ItemProperties = ({
  flyable,
  rideable,
  ...props
}: CardDropOptionEntityItemPropertiesProps) => {
  return (
    (flyable || rideable) && (
      <div className={styles['option-item-properties-container']} {...props}>
        {flyable && (
          <TagCategory className={styles['category']} variant={'fly'} />
        )}
        {rideable && (
          <TagCategory className={styles['category']} variant={'ride'} />
        )}
      </div>
    )
  );
};
