import { motion } from 'framer-motion';
import React from 'react';

import { Product } from '@/shared/types/common';
import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';

import styles from './price-card-btn.module.scss';

type FastBuyPriceCardFeatureProps = {
  product: Product;
  className?: string;
};

export const FastBuyPriceCardFeature = ({
  product,
}: FastBuyPriceCardFeatureProps) => {
  const handleFastBuy = () => {};

  return (
    <Button
      disabled={!product.maxCount || !product.price}
      as={motion.button}
      whileTap={{ scale: 0.95 }}
      className={styles['action-btn']}
      text="Fast Buy"
      variant="secondary"
      onClick={handleFastBuy}
      iconLeft={
        <Illustration
          id={'btn-icon'}
          name={'fast-buy'}
          spriteName={'icons'}
          style={{ color: '#6941C6' }}
        />
      }
    />
  );
};
