'use client';
import { ReactNode, useRef } from 'react';

export const WithShopItems = ({
  children,
}: {
  children: ({ items }: { items: any[] }) => ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const items: any[] = [];
  /*    useEffect(() => {
      sleep(2000).then(() => setCards(() => shop?.cards));
    }, []);*/

  if (!children) {
    throw new Error('Children not provided');
  }
  return children({ items });
};
