'use client';
import {
  usePostFavorites,
  usePostFavoritesAddHook,
  usePostFavoritesDeleteHook,
  usePostFavoritesMergeHook,
} from '@api-product/💙-избранное-🔓/💙-избранное-🔓';
import { usePostProductsPacksHook } from '@api-product/🧸-товары/🧸-товары';
import { useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { filterFavoriteItemsByProperties } from '@utils/favorite';
import { getShopItemsQueryFilters } from '@widgets/desktop/list-cards-wrappers/popular-items';
import { getCookie } from 'cookies-next';
import { useMemo } from 'react';
import { useIsClient } from 'usehooks-ts';

import { useStore } from '@/store/context';

import { QueryKeys } from './queryKeys';
export const useFavorite = (filters?: Map<any, any>) => {
  const queryClient = useQueryClient();
  const favoriteStore = useStore().favorite;
  const { mutateAsync } = usePostFavorites();
  const mergeFavoritesRequest = usePostFavoritesMergeHook();
  const addToFavoriteRequest = usePostFavoritesAddHook();
  const removeFromFavoriteRequest = usePostFavoritesDeleteHook();
  const getPacksRequest = usePostProductsPacksHook();

  const isClient = useIsClient();

  const queryKey = useMemo(
    () => [QueryKeys.FAVORITE, ...Array.from((filters ?? []).values()).flat()],
    [filters],
  );

  const localItemsNames = favoriteStore.getFavoriteNames();

  const { data: responseData, isLoading: favoriteLoading } = useQuery({
    queryKey,
    queryFn: () =>
      mutateAsync({
        data: getShopItemsQueryFilters(0, filters, 'favoriteSearch'),
      }),
    refetchInterval: 60000 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!getCookie('jwt'),
    gcTime: 0,
  });

  const { data: localItems, isLoading: localInfoLoading } = useQuery({
    queryKey: [QueryKeys.FAVORITE, localItemsNames],
    queryFn: () =>
      localItemsNames.length
        ? getPacksRequest({ realNames: localItemsNames })
        : null,
    refetchInterval: 60000 * 5,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    placeholderData: prev => prev,
    enabled: !getCookie('jwt'),
    gcTime: 0,
  });

  function mapToObject(map?: Map<any, any>): Record<string, any> | undefined {
    if (!map) {
      return undefined;
    }

    return Object.fromEntries(map);
  }

  const filteredCards = useMemo(() => {
    const myObject = mapToObject(filters);

    if (!localItems) return [];

    return filterFavoriteItemsByProperties(localItems?.data, myObject);
  }, [filters, localItemsNames.length]);

  const mergeFavorites = async () => {
    const mergeData = favoriteStore.items;

    if (mergeData.length) {
      await mergeFavoritesRequest(mergeData);
    }
    favoriteStore.clearItems();
  };

  const addToFavorite = async (realName: string) => {
    const token = getCookie('jwt');

    if (token) {
      await addToFavoriteRequest({ realName });
      queryClient.invalidateQueries({ queryKey });
    } else {
      favoriteStore.add({ realName, addedAt: new Date().toISOString() });
    }
  };

  const deleteItem = async (realName: string) => {
    const token = getCookie('jwt');

    if (token) {
      await removeFromFavoriteRequest({ realName });
      queryClient.invalidateQueries({ queryKey });
    } else {
      favoriteStore.delete(realName);
    }
  };

  const refetch = () => {
    queryClient.setQueryData(queryKey, null);
  };

  return {
    addToFavorite,
    deleteItem,
    items: (getCookie('jwt') ? responseData?.data.rows : filteredCards) ?? [],
    isLoading: favoriteLoading || localInfoLoading || !isClient,
    mergeFavorites,
    refetch,
  };
};
