'use client';
import { ListCardsWidgetProps } from '@widgets/desktop/list-cards/ui/list-cards.types';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIsClient, useMediaQuery } from 'usehooks-ts';

import { Label, Skeleton } from '@/shared/ui';

import styles from './list-cards.module.scss';

export const ListCardsWidget = observer(
  ({
    className,
    topSlot = (
      <Label labelVariant={'yellow'} icon={'graph-up'} variant={'primary'}>
        Popular Items
      </Label>
    ),
    bottomSlot,
    cardsVariant,
    isLoading,
    ref,
    ...props
  }: ListCardsWidgetProps) => {
    const listCardsClasses = cn(styles['list-cards'], className);
    const bottomSlotClasses = cn(
      styles['bottom-slot'],
      styles[`bottom-slot--${cardsVariant}`],
    );
    const isClient = useIsClient();

    const isLargeScreen = useMediaQuery('(min-width: 1920px)');
    const isMediumScreen = useMediaQuery('(min-width: 1440px)');
    const isSmallScreen = useMediaQuery('(min-width: 1024px)');

    const skeletonsToRender = isLargeScreen ? 16 : isMediumScreen ? 14 : 12;

    return (
      <div className={listCardsClasses} {...props}>
        <div className={styles['top-slot']}>{topSlot}</div>

        <div id={'list-card-bottom'} className={bottomSlotClasses}>
          {isClient && !isLoading && bottomSlot}
          {(!isClient || isLoading) &&
            Array(skeletonsToRender)
              .fill('')
              .map((_, idx) => (
                <Skeleton
                  key={`skeleton-list-cards-${idx}`}
                  className={cn(
                    styles['list-card-skeleton'],
                    styles[`variant--${cardsVariant}`],
                  )}
                />
              ))}
        </div>
      </div>
    );
  },
);
