'use client';
import { CardShoppingItemProps } from '@entities/desktop/cards/card-shopping-item/ui/card-shopping-item.types';
import { useCart } from '@hooks/queries/use-query-cart';
import { Game } from '@utils/AppConfig';
import { formatAge } from '@utils/helpers';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import {
  GlobalItemInfo,
  ItemMM2RarityVariants,
  ItemRarityVariants,
  ProductById,
} from '@/shared/types/common';
import { Divider, TagQuantity } from '@/shared/ui';
import { Item } from '@/shared/ui';
import { ItemRarityCard } from '@/shared/ui';
import Checkbox from '@/shared/ui/desktop/check-box/check-box';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';
import { TagCategory } from '@/shared/ui/tag-category';
import { TagRarity } from '@/shared/ui/tag-rarity';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './card-shopping-item.module.scss';

export const CardShoppingItemEntity = ({
  item,
  children,
  className,
  actionButtons,
  bottomSlot,
  isSoldout,
  ...props
}: CardShoppingItemProps) => {
  const { toggleSelected } = useCart();
  const cardLiveFeedClasses = cn(styles['card-shopping-item'], className);

  const getPricesGroups = (item: ProductById) => {
    const priceCounts: { [key: number]: number } = {};

    if (!item.prices) return [{ count: item.count, price: null }];

    item.prices?.slice(0, item.count).forEach(price => {
      priceCounts[price] = (priceCounts[price] || 0) + 1;
    });

    return Object.entries(priceCounts).map(([price, count]) => ({
      price: Number(price),
      count: count,
    }));
  };

  return (
    <article className={cardLiveFeedClasses} {...props}>
      <div className={styles['left-slot']}>
        <div className={styles['items-container']}>
          {item.prices && (
            <Checkbox
              checked={item.selected}
              onChange={() => toggleSelected(item)}
            />
          )}
          <div className={styles['items']}>
            {getPricesGroups(item).map((price, index) => (
              <div className={styles['item-info']} key={index}>
                <div className={styles['content']}>
                  <CardShoppingItemEntity.ItemImageSlot
                    src={item?.imageUri}
                    rare={item?.rare}
                    game={item.game}
                    count={price.count}
                    isSoldout={isSoldout}
                  />

                  <CardShoppingItemEntity.ItemInfo
                    title={item.name}
                    age={item.age}
                  />

                  <CardShoppingItemEntity.ItemProperties product={item} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles['action-btns']}>{actionButtons}</div>
      </div>
      {item.prices && (
        <>
          <Divider direction={'vertical'} />
          <div className={styles['price-info']}>
            {getPricesGroups(item).map(price => (
              <CardShoppingItemEntity.ItemPrice
                key={price.price}
                price={price.price ?? 0}
                status={null}
              />
            ))}
            {bottomSlot}
          </div>
        </>
      )}
    </article>
  );
};

CardShoppingItemEntity.ItemImageSlot = ({
  game,
  isSoldout,
  src,
  rare,
  count,
}: {
  game: Game;
  src: string;
  rare: ItemRarityVariants | ItemMM2RarityVariants;
  isSoldout?: boolean;
  count: number;
}) => {
  const colorScheme = game === 'mm2' ? colorsMM2Variant : colorsByVariant;

  return (
    <div
      className={cn(styles['item'], {
        [styles['item-unavailable']]: isSoldout,
      })}
    >
      <ItemRarityCard
        colorScheme={colorScheme}
        className={styles['background']}
        size={'75'}
        variant={rare}
      />
      <Item className={styles['item-variant']} size={'68'} src={src} />
      {isSoldout && <div className={styles['soldout-label']} />}
      <TagQuantity quantity={count} className={styles['item-count']} />
    </div>
  );
};

CardShoppingItemEntity.ItemInfo = ({
  title,
  age,
}: Omit<GlobalItemInfo, 'price'>) => {
  return (
    <div className={styles['title-block']}>
      {age && (
        <Typography className={styles['item-type']}>
          {formatAge(age)}
        </Typography>
      )}
      {title && (
        <Typography className={styles['item-title']}>{title}</Typography>
      )}
    </div>
  );
};

CardShoppingItemEntity.ItemPrice = observer(
  ({ price, status }: { price: number; status: 'unavailable' | null }) => {
    const currencySign = useStore().app.getCurrencySign;

    if (status === 'unavailable') {
      return (
        <div className={styles['price']}>
          <Typography className={styles['status']}>Not available</Typography>
        </div>
      );
    }

    return (
      <div className={styles['price']}>
        <Typography className={styles['current']}>
          {currencySign} {price}
        </Typography>
        <Typography decoration={'line-through'} className={styles['old']}>
          {currencySign} {price}
        </Typography>
      </div>
    );
  },
);

CardShoppingItemEntity.ItemProperties = ({
  product,
}: {
  product: ProductById;
}) => {
  return (
    <div className={styles['properties']}>
      <TagRarity variant={product.rare} />
      {product.flyable && <TagCategory variant={'fly'} />}
      {product.rideable && <TagCategory variant={'ride'} />}
    </div>
  );
};
