'use client';
import { CardItemEntity } from '@entities/desktop/cards';
import { CardItemShopEntityMobile } from '@entities/mobile/cards';
import { SidebarFavoritesEntity } from '@entities/sidebar';
import { useQueryString } from '@hooks/client';
import { useInfiniteProducts } from '@hooks/queries/use-infinite-query-products';
import { getShopItemsQueryFilters } from '@widgets/desktop/list-cards-wrappers/popular-items';
import { SHOP_QUERY_KEY } from '@widgets/desktop/shop/ui/cards/cards';
import cn from 'clsx';
import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useMediaQuery } from 'usehooks-ts';

import { Illustration, Skeleton, Typography } from '@/shared/ui';

import desktopStyles from './shop-cards-virtualized.module.scss';
import mobileStyles from './shop-cards-virtualized-mobile.module.scss';

export const ShopCardsVirtualized = ({ isMobile }: { isMobile?: boolean }) => {
  const bottomSentinelRef = useRef<HTMLDivElement>(null);

  const { mappedParams } = useQueryString();
  const styles = isMobile ? mobileStyles : desktopStyles;

  const {
    isLoading,
    items: shopItems,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteProducts({
    getQueryFiltersFn: getShopItemsQueryFilters,
    uniqueQueryKey: SHOP_QUERY_KEY,
    loadMoreRef: bottomSentinelRef,
    filters: mappedParams,
  });

  const isLargeScreen = useMediaQuery('(min-width: 1920px)');
  const isMobLargeScreen = useMediaQuery('(min-width: 768px)');

  const columnsDesktop = isLargeScreen ? 5 : 4;
  const columnsMobile = isMobLargeScreen ? 4 : 2;
  const columns = isMobile ? columnsMobile : columnsDesktop;

  const VirtuosoListComponents = (isFetching: boolean) => {
    return {
      List: forwardRef(
        (
          {
            style,
            children,
            ...props
          }: {
            style?: CSSProperties;
            children?: ReactNode;
          } & HTMLAttributes<HTMLDivElement>,
          ref: ForwardedRef<HTMLDivElement>,
        ) => (
          <div
            ref={ref}
            {...props}
            style={style}
            className={cn(styles['list'])}
          >
            {children}
            {isFetching &&
              Array.from({ length: 2 }).map((_, rowIndex) => (
                <div
                  key={`skeleton-row-${rowIndex}`}
                  className={styles['item']}
                >
                  {Array.from({ length: columns }).map((_, colIndex) => (
                    <Skeleton
                      key={`skeleton-${rowIndex}-${colIndex}`}
                      className={cn(styles['card-skeleton'])}
                    />
                  ))}
                </div>
              ))}
          </div>
        ),
      ),
      Item: ({
        children,
        item,
        ...props
      }: {
        children?: ReactNode;
        item: any;
      } & HTMLAttributes<HTMLDivElement>) => (
        <div {...props} className={cn(styles['item'])}>
          {children}
        </div>
      ),
    };
  };

  // Group data into rows based on current column count
  const groupedData = useMemo(() => {
    const rows = [];
    for (let i = 0; i < shopItems.length; i += columns) {
      rows.push(shopItems.slice(i, i + columns));
    }
    return rows;
  }, [shopItems, columns]);

  // Create row content renderer
  const rowContent = (index: number) => {
    const row = groupedData[index];

    return (
      <>
        {row.map((item, itemIndex) => {
          const actualIndex = index * columns + itemIndex;

          return actualIndex < shopItems.length ? (
            isMobile ? (
              <CardItemShopEntityMobile
                key={`card-${index}-${item.realName}-${item?.img}`}
                item={item}
                game={'adopt'}
              />
            ) : (
              <CardItemEntity
                key={`${item?.id}-${index}${actualIndex}`}
                applyHandleClickOnFullCard
                isMM2Variant={false}
                variant={'tertiary'}
                item={item}
              />
            )
          ) : null;
        })}
      </>
    );
  };

  const loadMore = useCallback(() => {
    if (isFetchingNextPage) return;

    return setTimeout(() => {
      fetchNextPage();
    }, 500);
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const shopRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const shopEl = shopRef.current;
    if (!shopEl) return;

    const handleMouseEnter = () => {
      hasNextPage && document.body.classList.add('scroll-on-hover');
      !hasNextPage && document.body.classList.remove('scroll-on-hover');
    };

    const handleMouseLeave = () => {
      document.body.classList.remove('scroll-on-hover');
    };

    shopEl.addEventListener('mouseenter', handleMouseEnter);
    shopEl.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      shopEl.removeEventListener('mouseenter', handleMouseEnter);
      shopEl.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [hasNextPage]);

  return (
    <div ref={shopRef} className={styles['wrapper']}>
      {isLoading && (
        <div className={cn(styles['list'])}>
          {Array.from({ length: 2 }).map((_, rowIndex) => (
            <div key={`skeleton-row-${rowIndex}`} className={styles['item']}>
              {Array.from({ length: columns }).map((_, colIndex) => (
                <Skeleton
                  key={`skeleton-${rowIndex}-${colIndex}`}
                  className={cn(styles['card-skeleton'])}
                />
              ))}
            </div>
          ))}
        </div>
      )}
      {!isLoading && (
        <>
          {shopItems.length <= 0 ? (
            <SidebarFavoritesEntity
              className={styles.empty}
              topSlot={
                <SidebarFavoritesEntity.Image variant={'shop-no-items'} />
              }
              middleSlot={
                <SidebarFavoritesEntity.Info
                  info={{
                    title: 'We didn`t find anything',
                    subtitle:
                      'Make sure that the name or properties of the item are written correctly, or pick up another item!',
                  }}
                />
              }
              bottomSlot={
                <SidebarFavoritesEntity.ActionButton
                  text={'Open categories'}
                  variant={'primary'}
                  iconRight={
                    <Illustration
                      id={'sidebar-fav-icon'}
                      spriteName={'icons'}
                      name={'square-arrow-right'}
                    />
                  }
                  iconLeft={
                    <Illustration
                      id={'sidebar-fav-icon'}
                      spriteName={'icons'}
                      name={'widget'}
                    />
                  }
                />
              }
            />
          ) : (
            <Virtuoso
              data={groupedData}
              endReached={loadMore}
              increaseViewportBy={200}
              overscan={20}
              itemContent={rowContent}
              components={VirtuosoListComponents(
                isFetchingNextPage || isLoading,
              )}
              style={{
                height: '100%',
                ...(isMobile && {
                  WebkitOverflowScrolling: 'touch',
                }),
              }}
            />
          )}
        </>
      )}
    </div>
  );
};
