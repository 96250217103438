import { StaticImageData } from 'next/image';
import { ReactNode } from 'react';

import { CategoryIcons } from '@/shared/types/common';
import {
  BannerCardItemVariant,
  CategoryItemVariant,
} from '@/shared/ui/item/item.types';
import { IllustrationCardCategoryProps } from '@/shared/ui/mobile/illustration-card-category/illustration-card-category.types';

import AuthLogin1 from '../../../../public/assets/faq/faq-auth-1.png';
import AuthLogin2 from '../../../../public/assets/faq/faq-auth-2.png';
import AuthLogin3 from '../../../../public/assets/faq/faq-auth-3.png';
import Purchase1 from '../../../../public/assets/faq/faq-purchase-1.png';
import Purchase2 from '../../../../public/assets/faq/faq-purchase-2.png';
type NavigationBtn = {
  item: BannerCardItemVariant;
  variant: IllustrationCardCategoryProps['variant'];
  title: string;
  icon: keyof CategoryIcons;
  iconColor?: string;
  starBackgroundColor?: string;
  href: string;
};

export const desktopNavBtnsTitles: { [key: string]: string } = {
  auth: 'Authorization and registration',
  purchase: 'Purchase',
  balance: 'Balance replenishment',
  withdrawal: 'Item withdrawal',
};
export const navigationFAQBtns: { [key: string]: NavigationBtn } = {
  auth: {
    icon: 'key-square',
    variant: 'purple',
    item: 'lock',
    title: 'Authorization',
    iconColor: '#6941C6',
    starBackgroundColor: '#F0ECF9',
    href: 'auth',
  },
  purchase: {
    icon: 'cart',
    variant: 'pink',
    item: 'cart',
    title: 'Purchase',
    iconColor: '#D41057',
    starBackgroundColor: '#FBF2F3',
    href: 'purchase',
  },
  balance: {
    icon: 'wallet',
    variant: 'green',
    item: 'ticket-sale',
    title: 'Balance',
    iconColor: '#40BB18',
    starBackgroundColor: '#ECF8E8',
    href: 'balance',
  },
  withdrawal: {
    icon: 'bill-list',
    variant: 'orange',
    item: 'clock-yellow',
    title: 'Withdrawal',
    iconColor: '#FE9920',
    starBackgroundColor: '#FFF5E9',
    href: 'withdrawal',
  },
};

export type Question = {
  title: string;
  text: ReactNode;
  img?: string | StaticImageData;
};

export type QuestionsByPage = {
  [key: string]: Question[];
};
export const questionsByPage: QuestionsByPage = {
  auth: [
    {
      title: 'How to register using "login/password"?',
      img: AuthLogin1,
      text: (
        <>
          <div>
            To register, click &#34;Profile&#34; in the top menu, then select
            &#34;Create an account&#34; and fill in the form.
          </div>
          <div>
            {' '}
            A nickname must be 3-24 characters long and can include letters
            (A-Z), numbers, and the symbols &#34;_&#34; and &#34;.&#34;, with up
            to 2 special symbols that can&#39;t be consecutive. A password must
            be 6-32 characters long
          </div>
        </>
      ),
    },
    {
      title: 'How to register via Google or Discord?',
      img: AuthLogin2,
      text: (
        <>
          <div>
            Click the button for your preferred third-party service (e.g.,
            &#34;Discord&#34;, “Google”). If prompted, log into your account or
            select one from the list of accounts you&#39;re already signed into,
            then click &#34;Allow.&#34;
          </div>
        </>
      ),
    },
    {
      title: 'Forgot password or login',
      img: AuthLogin3,
      text: (
        <div>
          You can recover access to your account in the login window:
          <p>
            Enter the email you used to register, then enter the 6-digit code
            that will be sent to your email and create a new password. If you
            forgot your login or email, please contact our support service on
            the site.
          </p>
        </div>
      ),
    },
    {
      title:
        'The Discord/Google account used to log in has been lost. What should I do?',
      text: (
        <div>
          If your Discord/Google account has been blocked, deleted, or you have
          permanently lost access to it, please contact our support service on
          the site. To recover the account, you will need to prove that it
          actually belongs to you (provide information about the inventory on
          the account or balance, etc.).
        </div>
      ),
    },
  ],
  purchase: [
    {
      title: 'How to transfer purchased pets to my account?',
      text: (
        <div>
          All purchased items after payment can be found in the
          &#34;Inventory&#34; tab of your profile, after which you have exactly
          1 hour to withdraw them. To transfer the purchased items to your
          Roblox account, you need to select the items you want to withdraw and
          click the &#34;Claim&#34; button. After that, enter your nickname in
          Roblox and select your account, then follow the on-screen
          instructions.
        </div>
      ),
    },
    {
      title: 'I bought the wrong item or decided not to buy it',
      text: <div>To cancel the purchase, please contact customer support.</div>,
    },

    {
      title: 'Are toys/strollers/wings available for purchase?',
      text: <div>No</div>,
    },
    {
      title: "I can't buy the item",
      text: 'If, for some reason, you are unable to purchase the item, please contact customer support.',
    },
    {
      title: 'Can I buy items on the website with bucks?',
      text: (
        <div>
          We do not accept in-game bucks as payment on the site when buying
          items.
        </div>
      ),
    },
    {
      img: Purchase1,
      title: "I didn't have time to transfer the items to my account",
      text: (
        <div>
          If you don&#39;t transfer the items to your account in time, they will
          be removed from your inventory, and your balance will be credited with
          their value.
        </div>
      ),
    },
    {
      img: Purchase2,
      title: 'How can I view my topup and purchase history?',
      text: (
        <div>
          You can view both your topup history and your purchase history in the
          &#34;History&#34; tab of your profile. Go to this page and select the
          type of history you need.
        </div>
      ),
    },
  ],
  balance: [
    {
      title: 'How can I view my topup and purchase history?',
      text: (
        <div>
          If the time to withdraw the item has expired and the item has been
          removed from your inventory, but your balance has not been updated,
          please wait 5 to 10 minutes. If your balance has not been updated
          after this time, contact customer support.
        </div>
      ),
    },
    {
      title: 'How to top up your balance',
      text: (
        <div>
          You cannot directly top up your balance. If you purchase items and do
          not withdraw them in time, the money for those items will be credited
          to your balance. You can use this balance to fully or partially pay
          for future orders.
        </div>
      ),
    },
  ],
  withdrawal: [
    {
      title: 'The bot is not accepting the trade',
      text: (
        <div>
          Check the message the bot sent you in the chat. You may have listed
          restricted items in the trade (Clothing/Food/Toys/Gifts/Wings). Try
          making the trade again later. If it still doesn&#39;t work, contact
          our support team—we&#39;re here to help!
        </div>
      ),
    },
    {
      title: 'The bot is declining the trade',
      text: (
        <div>
          <p>This can happen for several reasons:</p>

          <p>
            • You entered the wrong username. Make sure to enter your Roblox
            account username &nbsp;&nbsp;&nbsp;— &nbsp;RP names won&#39;t work.
            <br />
            • You entered the username of a different account. Your browser and
            the Roblox app may &nbsp;&nbsp; be logged into different accounts at
            the same time.
            <br />
            • The trade time with the bot has expired. The remaining time is
            shown in the Inventory &nbsp;&nbsp;&nbsp;tab. If there is no timer,
            it means the trade time has already run out.
            <br />
          </p>

          <p>
            Keep in mind that during maintenance, bots may also stop working.
            Please wait until the maintenance is over, then try withdrawing your
            pet, potion, or vehicle again.&#34;
          </p>
        </div>
      ),
    },
    {
      title: 'What happens if the withdrawal time expires?',

      text: (
        <div>
          If the time to withdraw the item expires, the item will disappear from
          your inventory. The money spent on the item will be refunded to your
          balance, which you can use for partial or full payment on future
          purchases.
        </div>
      ),
    },
    {
      title: 'The bot is not on the server',
      text: (
        <div>
          As soon as you click the green (Join) button, you will be redirected
          to a VIP Adopt Me server where you and your bot will be. If the bot is
          not on the server, try waiting a few minutes or recreate the trade on
          the website.
        </div>
      ),
    },
    {
      title: 'The bot is taking a long time to be assigned for the trade',
      text: (
        <div>
          <p>A long bot search can happen for several reasons:</p>

          <p>
            • Most or all bots are undergoing maintenance.
            <br />
            • High load on bots/website.
            <br />
            • The bot you requested is broken.
            <br />
          </p>

          <p>
            In this case, we recommend trying again a little later. You can
            always cancel the bot search or contact the website support for more
            information.
          </p>
        </div>
      ),
    },
  ],
};
export const titleByPage = {
  auth: 'Authorization and registration',
  purchase: 'Purchase',
  balance: 'Balance replenishment',
  withdrawal: 'Item withdrawal',
};
