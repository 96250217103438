'use client';
import { CardItemEntity } from '@entities/desktop/cards';
import { SidebarFavoritesEntity } from '@entities/sidebar';
import { useInfiniteProducts } from '@hooks/queries/use-infinite-query-products';
import { useProducts } from '@hooks/queries/use-query-products';
import { ContentWidget, ListCardsWidgetVirtualized } from '@widgets/desktop';
import { ShopCardsVirtualized } from '@widgets/desktop/list-cards/ui/shop-cards-virtualized';
import {
  getPopularsQueryFilters,
  getShopItemsQueryFilters,
  POPULAR_QUERY_KEY,
} from '@widgets/desktop/list-cards-wrappers/popular-items';
import React, { useRef } from 'react';

import { SeeAllButton } from '@/shared/components/see-all-button';
import { Illustration, Label } from '@/shared/ui';

import styles from './cards.module.scss';

export const SHOP_QUERY_KEY = 'shop-products' as const;

export const ShopCards = () => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const {
    isLoading,
    items: shopItems,
    isFetching,
    fetchNextPage,
  } = useInfiniteProducts({
    getQueryFiltersFn: getShopItemsQueryFilters,
    uniqueQueryKey: SHOP_QUERY_KEY,
    loadMoreRef: loadMoreRef,
  });

  const {
    isLoading: isPopularLoading,
    items: popularItems,
    isFetching: isPopularFetching,
  } = useProducts({
    getQueryFiltersFn: getPopularsQueryFilters,
    uniqueQueryKey: POPULAR_QUERY_KEY,
  });

  const slicedItems = popularItems?.slice(0, 10) || [];

  return (
    <>
      {/* <ListCardsWidgetVirtualized
        className={styles['virtualized-list']}
        isLoading={isLoading}
        isFetching={isFetching}
        cardsVariant={'tertiary'}
        items={shopItems}
        loadMore={() => fetchNextPage()}
        itemContent={(index, item) => (
          <CardItemEntity
            key={`${item.id}-${index}`}
            applyHandleClickOnFullCard
            isMM2Variant={false}
            variant={'tertiary'}
            item={item}
          />
        )}
      />*/}

      <ContentWidget
        isLoading={isPopularLoading || isPopularFetching}
        items={slicedItems}
        skeletonCount={10}
        headerSlot={
          <Label labelVariant={'yellow'} icon={'graph-up'} variant={'primary'}>
            Popular Items
          </Label>
        }
        emptySlot={
          <SidebarFavoritesEntity
            className={styles.empty}
            topSlot={<SidebarFavoritesEntity.Image variant={'shop-no-items'} />}
            middleSlot={
              <SidebarFavoritesEntity.Info
                info={{
                  title: 'We didn`t find anything',
                  subtitle:
                    'Make sure that the name or properties of the item are written correctly, or pick up another item!',
                }}
              />
            }
            bottomSlot={
              <SidebarFavoritesEntity.ActionButton
                text={'Open categories'}
                variant={'primary'}
                iconRight={
                  <Illustration
                    id={'sidebar-fav-icon'}
                    spriteName={'icons'}
                    name={'square-arrow-right'}
                  />
                }
                iconLeft={
                  <Illustration
                    id={'sidebar-fav-icon'}
                    spriteName={'icons'}
                    name={'widget'}
                  />
                }
              />
            }
          />
        }
      />
    </>
  );
};
