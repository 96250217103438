'use client';
import { useQueryString } from '@hooks/client';
import { useFavorite } from '@hooks/queries/use-query-favorite';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { ButtonFavorite } from '@/shared/ui/desktop/button-favorite';
export type AddToFavoriteFeatureProps = {
  variant: 'primary' | 'secondary' | 'tertiary';
  realName: string;
  isMobile?: boolean;
};
export const AddToFavoriteFeature = observer(
  ({ variant, realName, isMobile }: AddToFavoriteFeatureProps) => {
    const isClient = useIsClient();
    const { mappedParams } = useQueryString();
    const { items, addToFavorite, deleteItem } = useFavorite(mappedParams);
    const likedItem = items.find(item => item.realName === realName);

    if (!isClient) {
      return null;
    }

    return (
      <ButtonFavorite
        isMobile={isMobile}
        variant={
          variant === 'tertiary' || variant === 'secondary'
            ? 'secondary'
            : 'primary'
        }
        onPress={() =>
          !likedItem ? addToFavorite(realName) : deleteItem(realName)
        }
        isLiked={Boolean(likedItem)}
      />
    );
  },
);
