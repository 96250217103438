import { CartItemsResponse } from '@hooks/queries/common.types';

import { ProductById } from '@/shared/types/common';

export const getAvailableCartItems = (items: (ProductById | undefined)[]) => {
  return items?.reduce((accumulator: ProductById[], item) => {
    if (!item) return accumulator;
    const min = Math.min(item?.prices?.length ?? 0, item?.count ?? 0);

    if (min > 0) {
      accumulator.push({ ...item, count: min });
    }
    return accumulator;
  }, []);
};

export const getUnavailableCartItems = (items: (ProductById | undefined)[]) => {
  return items?.reduce((accumulator: ProductById[], item) => {
    if (!item) return accumulator;

    const pricesLength = item?.prices?.length ?? 0;
    const count = item?.count ?? 0;

    if (count > pricesLength) {
      accumulator.push({ ...item, count: count - pricesLength, prices: null });
    }

    return accumulator;
  }, []);
};

export const toggleSelected =
  (itemId: number) => (prevCart: CartItemsResponse) => {
    {
      const updatedList = prevCart.data.list.map(currItem => {
        if (currItem.id === itemId) {
          return { ...currItem, selected: !currItem.selected };
        }
        return currItem;
      });

      return {
        ...prevCart,
        data: {
          ...prevCart.data,
          list: updatedList,
          isAllSelected: updatedList.every(item => item.selected),
        },
      };
    }
  };

export const cartRemoveSelected = (prevCart: CartItemsResponse) => {
  const filteredList = prevCart.data.list.filter(item => !item.selected);

  return {
    ...prevCart,
    data: {
      ...prevCart.data,
      list: filteredList,
      count: filteredList.length,
      isAllSelected: false,
    },
  };
};

export const toggleAllSelected =
  (allSelected: boolean) => (prevCart: CartItemsResponse) => {
    const updatedList = prevCart.data.list.map(item => {
      return { ...item, selected: !allSelected };
    });

    return {
      ...prevCart,
      data: {
        ...prevCart.data,
        list: updatedList,
        isAllSelected: !allSelected,
      },
    };
  };
