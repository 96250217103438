/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * API Сервиса Маркет (Adopt Me) #market #adopt_me
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';

import { FavoriteItemsResponse } from '@/shared/types/common';

import type { BodyType, ErrorType } from '../../axios-client';
import { useCustomClient } from '../../axios-client';
import type {
  PostFavorites200,
  PostFavorites400,
  PostFavorites401,
  PostFavoritesAdd200,
  PostFavoritesAdd400,
  PostFavoritesAdd401,
  PostFavoritesAdd404,
  PostFavoritesAdd409,
  PostFavoritesAdd429,
  PostFavoritesAddBody,
  PostFavoritesBody,
  PostFavoritesDelete200,
  PostFavoritesDelete400,
  PostFavoritesDelete401,
  PostFavoritesDeleteBody,
  PostFavoritesMerge200,
  PostFavoritesMerge400,
  PostFavoritesMerge401,
  PostFavoritesMerge429,
  PostFavoritesMergeBody,
} from '.././models';

/**
 * @summary Получать избранное
 */
export const usePostFavoritesHook = () => {
  const postFavorites = useCustomClient<FavoriteItemsResponse>();

  return useCallback(
    (postFavoritesBody: BodyType<PostFavoritesBody>, signal?: AbortSignal) => {
      return postFavorites({
        url: '/favorites',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: postFavoritesBody,
        signal,
      });
    },
    [postFavorites],
  );
};

export const usePostFavoritesMutationOptions = <
  TError = ErrorType<PostFavorites400 | PostFavorites401>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostFavoritesHook>>>,
    TError,
    { data: BodyType<PostFavoritesBody> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostFavoritesHook>>>,
  TError,
  { data: BodyType<PostFavoritesBody> },
  TContext
> => {
  const mutationKey = ['postFavorites'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const postFavorites = usePostFavoritesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostFavoritesHook>>>,
    { data: BodyType<PostFavoritesBody> }
  > = props => {
    const { data } = props ?? {};

    return postFavorites(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostFavoritesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostFavoritesHook>>>
>;
export type PostFavoritesMutationBody = BodyType<PostFavoritesBody>;
export type PostFavoritesMutationError = ErrorType<
  PostFavorites400 | PostFavorites401
>;

/**
 * @summary Получать избранное
 */
export const usePostFavorites = <
  TError = ErrorType<PostFavorites400 | PostFavorites401>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostFavoritesHook>>>,
    TError,
    { data: BodyType<PostFavoritesBody> },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePostFavoritesHook>>>,
  TError,
  { data: BodyType<PostFavoritesBody> },
  TContext
> => {
  const mutationOptions = usePostFavoritesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Добавить товар в избранное
 */
export const usePostFavoritesAddHook = () => {
  const postFavoritesAdd = useCustomClient<PostFavoritesAdd200>();

  return useCallback(
    (
      postFavoritesAddBody: BodyType<PostFavoritesAddBody>,
      signal?: AbortSignal,
    ) => {
      return postFavoritesAdd({
        url: '/favorites/add',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: postFavoritesAddBody,
        signal,
      });
    },
    [postFavoritesAdd],
  );
};

export const usePostFavoritesAddMutationOptions = <
  TError = ErrorType<
    | PostFavoritesAdd400
    | PostFavoritesAdd401
    | PostFavoritesAdd404
    | PostFavoritesAdd409
    | PostFavoritesAdd429
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostFavoritesAddHook>>>,
    TError,
    { data: BodyType<PostFavoritesAddBody> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostFavoritesAddHook>>>,
  TError,
  { data: BodyType<PostFavoritesAddBody> },
  TContext
> => {
  const mutationKey = ['postFavoritesAdd'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const postFavoritesAdd = usePostFavoritesAddHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostFavoritesAddHook>>>,
    { data: BodyType<PostFavoritesAddBody> }
  > = props => {
    const { data } = props ?? {};

    return postFavoritesAdd(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostFavoritesAddMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostFavoritesAddHook>>>
>;
export type PostFavoritesAddMutationBody = BodyType<PostFavoritesAddBody>;
export type PostFavoritesAddMutationError = ErrorType<
  | PostFavoritesAdd400
  | PostFavoritesAdd401
  | PostFavoritesAdd404
  | PostFavoritesAdd409
  | PostFavoritesAdd429
>;

/**
 * @summary Добавить товар в избранное
 */
export const usePostFavoritesAdd = <
  TError = ErrorType<
    | PostFavoritesAdd400
    | PostFavoritesAdd401
    | PostFavoritesAdd404
    | PostFavoritesAdd409
    | PostFavoritesAdd429
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostFavoritesAddHook>>>,
    TError,
    { data: BodyType<PostFavoritesAddBody> },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePostFavoritesAddHook>>>,
  TError,
  { data: BodyType<PostFavoritesAddBody> },
  TContext
> => {
  const mutationOptions = usePostFavoritesAddMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Удалить товар из избранных
 */
export const usePostFavoritesDeleteHook = () => {
  const postFavoritesDelete = useCustomClient<PostFavoritesDelete200>();

  return useCallback(
    (
      postFavoritesDeleteBody: BodyType<PostFavoritesDeleteBody>,
      signal?: AbortSignal,
    ) => {
      return postFavoritesDelete({
        url: '/favorites/delete',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: postFavoritesDeleteBody,
        signal,
      });
    },
    [postFavoritesDelete],
  );
};

export const usePostFavoritesDeleteMutationOptions = <
  TError = ErrorType<PostFavoritesDelete400 | PostFavoritesDelete401>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostFavoritesDeleteHook>>>,
    TError,
    { data: BodyType<PostFavoritesDeleteBody> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostFavoritesDeleteHook>>>,
  TError,
  { data: BodyType<PostFavoritesDeleteBody> },
  TContext
> => {
  const mutationKey = ['postFavoritesDelete'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const postFavoritesDelete = usePostFavoritesDeleteHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostFavoritesDeleteHook>>>,
    { data: BodyType<PostFavoritesDeleteBody> }
  > = props => {
    const { data } = props ?? {};

    return postFavoritesDelete(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostFavoritesDeleteMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostFavoritesDeleteHook>>>
>;
export type PostFavoritesDeleteMutationBody = BodyType<PostFavoritesDeleteBody>;
export type PostFavoritesDeleteMutationError = ErrorType<
  PostFavoritesDelete400 | PostFavoritesDelete401
>;

/**
 * @summary Удалить товар из избранных
 */
export const usePostFavoritesDelete = <
  TError = ErrorType<PostFavoritesDelete400 | PostFavoritesDelete401>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostFavoritesDeleteHook>>>,
    TError,
    { data: BodyType<PostFavoritesDeleteBody> },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePostFavoritesDeleteHook>>>,
  TError,
  { data: BodyType<PostFavoritesDeleteBody> },
  TContext
> => {
  const mutationOptions = usePostFavoritesDeleteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Объединить избранные
 */
export const usePostFavoritesMergeHook = () => {
  const postFavoritesMerge = useCustomClient<PostFavoritesMerge200>();

  return useCallback(
    (
      postFavoritesMergeBody: BodyType<PostFavoritesMergeBody>,
      signal?: AbortSignal,
    ) => {
      return postFavoritesMerge({
        url: '/favorites/merge',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: postFavoritesMergeBody,
        signal,
      });
    },
    [postFavoritesMerge],
  );
};

export const usePostFavoritesMergeMutationOptions = <
  TError = ErrorType<
    PostFavoritesMerge400 | PostFavoritesMerge401 | PostFavoritesMerge429
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostFavoritesMergeHook>>>,
    TError,
    { data: BodyType<PostFavoritesMergeBody> },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostFavoritesMergeHook>>>,
  TError,
  { data: BodyType<PostFavoritesMergeBody> },
  TContext
> => {
  const mutationKey = ['postFavoritesMerge'];
  const { mutation: mutationOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey } };

  const postFavoritesMerge = usePostFavoritesMergeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostFavoritesMergeHook>>>,
    { data: BodyType<PostFavoritesMergeBody> }
  > = props => {
    const { data } = props ?? {};

    return postFavoritesMerge(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostFavoritesMergeMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostFavoritesMergeHook>>>
>;
export type PostFavoritesMergeMutationBody = BodyType<PostFavoritesMergeBody>;
export type PostFavoritesMergeMutationError = ErrorType<
  PostFavoritesMerge400 | PostFavoritesMerge401 | PostFavoritesMerge429
>;

/**
 * @summary Объединить избранные
 */
export const usePostFavoritesMerge = <
  TError = ErrorType<
    PostFavoritesMerge400 | PostFavoritesMerge401 | PostFavoritesMerge429
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostFavoritesMergeHook>>>,
    TError,
    { data: BodyType<PostFavoritesMergeBody> },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePostFavoritesMergeHook>>>,
  TError,
  { data: BodyType<PostFavoritesMergeBody> },
  TContext
> => {
  const mutationOptions = usePostFavoritesMergeMutationOptions(options);

  return useMutation(mutationOptions);
};
