import { InventoryItem } from '@/shared/types/common';

export const inventoryItems: InventoryItem[] = [
  {
    inventoryType: 'gift',
    rare: 'legendary',
    type: 'egg',
    realName: 'royal_egg',
    game: 'adopt',
    name: 'Royal Egg',
    subtype: null,
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/royal_egg_218155608038015716861250016536746955046.png',
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    id: 27271,
    price: 3.22,
    age: null,
    flyable: null,
    rideable: null,
    pumping: null,
    timer: '5d',
    timesUp: false,
  },
  {
    inventoryType: 'gift',
    rare: 'common',
    type: 'transport',
    game: 'adopt',
    realName: 'neon_black_scooter',
    name: 'Neon Black Scooter',
    subtype: 'scooters',
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/neon_black_scooter_181279737671050341577192616449886507435.png',
    description:
      'The Caravan Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    id: 27448,
    price: 43.32,
    age: null,
    flyable: null,
    rideable: null,
    pumping: null,
    timer: '3d',
    timesUp: false,
  },
  {
    inventoryType: 'basic',
    rare: 'uncommon',
    type: 'pet',
    game: 'adopt',
    realName: 'urban_2023_tawny_frogmouth',
    name: 'Tawny Frogmouth',
    subtype: null,
    description:
      'The Caravan Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/urban_2023_tawny_frogmouth_119331012819097045543021857508892653948.png',
    id: 13573,
    price: 144,
    age: 'newborn',
    flyable: true,
    rideable: true,
    pumping: 'default',
    timer: '1d',
    timesUp: false,
  },
  {
    inventoryType: 'basic',
    rare: 'uncommon',
    type: 'pet',
    game: 'adopt',
    realName: 'basic_egg_2022_camel',
    name: 'Camel',
    subtype: null,
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/basic_egg_2022_camel_136141241958015511182421130414687560343.png',
    description:
      'The Retired-egg Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    id: 23597,
    price: 33,
    age: 'reborn',
    flyable: true,
    rideable: true,
    pumping: 'neon',

    timer: 300,
    timesUp: false,
  },
  {
    inventoryType: 'limited',
    rare: 'uncommon',
    type: 'pet',
    game: 'adopt',
    realName: 'halloween_2021_black_mummy_cat',
    name: 'Halloween Black Mummy Cat',
    subtype: null,
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/halloween_2021_black_mummy_cat_53705332753530036708937767565677110703.png',
    description:
      'The Baku Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    id: 33573,
    price: 144,
    age: 'newborn',
    flyable: true,
    rideable: true,
    pumping: 'default',
    timer: 100,
    timesUp: false,
  },
  {
    inventoryType: 'limited',
    rare: 'uncommon',
    type: 'pet',
    game: 'adopt',
    realName: 'basic_egg_2022_poodle',
    name: 'Poodle',
    subtype: null,
    imageUri:
      'https://starpets.ams3.cdn.digitaloceanspaces.com/AM/basic_egg_2022_poodle_10009173492233461134036485935467892038.png',
    id: 43597,
    price: 33,
    description:
      'The Woodland-egg Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    age: 'reborn',
    flyable: true,
    rideable: true,
    pumping: 'neon',
    timer: 1000,
    timesUp: false,
  },
  {
    inventoryType: 'gift',
    rare: 'ancient',
    type: 'knife',
    game: 'mm2',
    realName: 'basic_egg_2022_poodle',
    name: 'Zombie Chroma Knife',
    subtype: null,
    imageUri:
      'https://bloxway.ams3.cdn.digitaloceanspaces.com/img/chroma-knife.webp',
    description:
      'The Woodland-egg Zombie Ponycycle is a limited legendary pet in Adopt Me!' +
      ' that was released on June 16, 2022. It can be obtained through hatching' +
      ' a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that' +
      ' its respective event has ended, it can only be obtained through trading.',
    id: 111116,
    price: 33,
    age: null,
    flyable: null,
    rideable: null,
    pumping: null,
    timer: 300,
    timesUp: false,
  },
];
