import Link from 'next/link';
import React from 'react';

import { Breadcrumbs } from '@/shared/ui/desktop/breadcrumbs';
import {
  BreadcrumbsItem,
  BreadcrumbsItemSeparator,
} from '@/shared/ui/desktop/breadcrumbs/breadcrumbs';

import styles from './breadcrumbs.module.scss';
import { CategoryTitle } from './category-title';

export const ShopBreadcrumbs = () => {
  return (
    <Breadcrumbs className={styles.breadcrumbs}>
      <BreadcrumbsItem as={Link} href={'/'}>
        Market
      </BreadcrumbsItem>
      <BreadcrumbsItemSeparator />
      <BreadcrumbsItem as={Link} href={'/categories'}>
        Catalog
      </BreadcrumbsItem>
      <BreadcrumbsItemSeparator />
      <CategoryTitle />
    </Breadcrumbs>
  );
};
