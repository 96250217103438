import {
  CardLiveFeedEntityBottomSlotProps,
  CardLiveFeedEntityItemProps,
  CardLiveFeedEntityTagsProps,
  CardLiveFeedProps,
} from '@entities/desktop/cards/card-live-feed/ui/card-live-feed.types';
import { CardLiveFeedEntityItemInfo } from '@entities/desktop/cards/card-live-feed/ui/item-info';
import cn from 'clsx';

import { Item, TagRarity } from '@/shared/ui';
import { ItemRarityLiveFeed } from '@/shared/ui';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-live-feed/item-rarity-live-feed';
import { TagCategory } from '@/shared/ui/tag-category';

import styles from './card-live-feed.module.scss';

export const CardLiveFeedEntity = ({
  children,
  className,
  item,
  info,
  topSlot = <CardLiveFeedEntity.Item item={item} />,
  bottomSlot = (
    <CardLiveFeedEntity.BottomSlot
      colorScheme={item.game === 'mm2' ? colorsMM2Variant : colorsByVariant}
      rare={item.rare}
      item={item}
      withRarity={item.game === 'mm2'}
    />
  ),
  ...props
}: CardLiveFeedProps) => {
  const cardLiveFeedClasses = cn(styles['card-live-feed'], className);

  return (
    <article className={cardLiveFeedClasses} {...props}>
      <div className={cn(styles['card-live-feed--content'])}>
        <div className={cn(styles['card-live-feed--content__info'])}>
          <CardLiveFeedEntityItemInfo info={info} />
        </div>
        <div className={cn(styles['card-live-feed--content__item'])}>
          {topSlot}
        </div>
        <CardLiveFeedEntity.Tags isRarity={item.game === 'mm2'}>
          {bottomSlot}
        </CardLiveFeedEntity.Tags>
      </div>
    </article>
  );
};

// eslint-disable-next-line react/display-name
CardLiveFeedEntity.Tags = ({
  children,
  isRarity,
}: CardLiveFeedEntityTagsProps) => {
  const cardLiveFeedEntityTagsClasses = cn(styles['card-live-feed--tags'], {
    [styles['card-live-feed--tags-rarity']]: isRarity,
  });

  return <div className={cardLiveFeedEntityTagsClasses}>{children}</div>;
};

CardLiveFeedEntity.BottomSlot = ({
  item,
  rare,
  withRarity,
  colorScheme,
}: CardLiveFeedEntityBottomSlotProps) => {
  if (withRarity || item.type !== 'pet') {
    return <TagRarity color={colorScheme[rare]} variant={rare} />;
  }

  return (
    <>
      {item.products[0].flyable && (
        <TagCategory className={styles['tag-category']} variant={'fly'} />
      )}
      {item.products[0].rideable && (
        <TagCategory className={styles['tag-category']} variant={'ride'} />
      )}
      <TagCategory
        className={styles['tag-category']}
        variant={item.products[0].pumping!}
      />
    </>
  );
};

// eslint-disable-next-line react/display-name
CardLiveFeedEntity.Item = ({ item }: CardLiveFeedEntityItemProps) => {
  const colorScheme = item.game === 'mm2' ? colorsMM2Variant : colorsByVariant;

  const className = cn(
    styles['item-rarity'],
    styles[`item-rarity-${item.game}`],
  );

  return (
    <>
      <ItemRarityLiveFeed
        colorScheme={colorScheme}
        size={'58'}
        className={className}
        variant={item.rare}
        isMM2Variant={item.game === 'mm2'}
      />
      <Item className={styles['item']} size={'96'} src={item.img} />
    </>
  );
};
