import cn from 'clsx';
import { ReactNode } from 'react';

import { Item, ItemRarityCard, TagCategory } from '@/shared/ui';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';

import styles from './card-seller-item.module.scss';
import { CardSellerItemProps } from './card-seller-item.types';
export const CardSellerItemEntity = ({
  item,
  className,
}: CardSellerItemProps) => {
  const colorScheme = item.game === 'mm2' ? colorsMM2Variant : colorsByVariant;

  return (
    <div className={cn(styles['item'], className)}>
      <div className={styles['center']}>
        <ItemRarityCard
          colorScheme={colorScheme}
          className={styles['background']}
          size={'50'}
          variant={item.rare}
        />
        <Item
          className={styles['item-variant']}
          size={'42'}
          src={item.imageUri}
        />
      </div>

      <div className={styles['item-tags']}>
        {item.pumping && (
          <TagCategory className={styles['tag']} variant={item.pumping} />
        )}
        {item.flyable && (
          <TagCategory className={styles['tag']} variant={'fly'} />
        )}
        {item.rideable && (
          <TagCategory className={styles['tag']} variant={'ride'} />
        )}
      </div>
    </div>
  );
};

export const CardSellerItemsWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <div className={styles['wrapper']}>{children}</div>;
};
