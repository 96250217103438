'use client';
import { CardItemProps } from '@entities/desktop/cards/card-item/ui/card-item.types';
import { AddToCartFeature } from '@features/desktop/add-to-cart';
import { AddToFavoriteFeature } from '@features/desktop/add-to-favorite/ui';
import { SelectPetPropertiesFeature } from '@features/select-pet-properties';
import { sortProperties } from '@features/select-pet-properties/ui/select-pet-properties';
import { useQueryString } from '@hooks/client';
import { formatAge } from '@utils/helpers';
import cn from 'clsx';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';

import { ItemRarityCard, ItemRarityLiveFeed } from '@/shared/ui';
import { CardItemInfo, CardItemTop } from '@/shared/ui/cards';
import { ItemRarityShopCardBg } from '@/shared/ui/desktop/item-rarity-shop-card-bg';
import {
  colorsByVariant as colorsByVariantShopCardBg,
  colorsMM2Variant as colorsMM2VariantShopCardBg,
} from '@/shared/ui/desktop/item-rarity-shop-card-bg/item-rarity-shop-card-bg';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';
import {
  colorsByVariant as colorsByVariantLiveFeed,
  colorsMM2Variant as colorsMM2VariantLiveFeed,
} from '@/shared/ui/item-rarity-live-feed/item-rarity-live-feed';

import styles from './card-item.module.scss';
export const CardItemEntity = ({
  className,
  variant = 'primary',
  item,
  game,
  applyHandleClickOnFullCard = false,
  disabled,
  ref,
  id,
  tagsInfo,
  ...props
}: CardItemProps) => {
  const cardItemClasses = cn(
    styles['card-item'],
    styles['variant'],
    styles[`variant--${variant}`],
    { [styles['card-item--pointer']]: applyHandleClickOnFullCard },
    { [styles['disabled']]: disabled },
    className,
  );
  const { mappedParams } = useQueryString();

  const sorting = mappedParams.has('sort') && mappedParams.get('sort')[0];

  const router = useRouter();
  const defaultSelectedCard =
    item?.type === 'pet'
      ? sortProperties(item.products, sorting === 'cheaper' ? 'asc' : 'desc')[0]
      : item.products[0];
  const [selectedCard, setSelectedCard] = useState(defaultSelectedCard);
  const handleClick = () => {
    return applyHandleClickOnFullCard
      ? router.push(`/shop/${item.type}/${item?.realName}/${selectedCard.id}`)
      : undefined;
  };

  const colorSchemeItemRarity =
    game === 'mm2' ? colorsMM2Variant : colorsByVariant;

  const colorSchemeLiveFeed =
    game === 'mm2' ? colorsMM2VariantLiveFeed : colorsByVariantLiveFeed;

  const colorSchemeShopCardBg =
    game === 'mm2' ? colorsMM2VariantShopCardBg : colorsByVariantShopCardBg;

  return (
    <article
      id={id}
      onClick={handleClick}
      className={cardItemClasses}
      ref={ref}
    >
      <div onClick={handleClick} className={cn(styles['content'])}>
        <CardItemTop
          isMM2Variant={game === 'mm2'}
          variant={variant}
          className={styles['item']}
          tagsInfo={tagsInfo}
          onClick={handleClick}
          src={item.img}
          topRightSlot={
            <AddToFavoriteFeature
              variant={variant === 'tertiary' ? 'secondary' : 'primary'}
              realName={item.realName}
            />
          }
          backgroundIllustration={
            variant !== 'tertiary' ? (
              variant !== 'quaternary' ? (
                <div
                  className={styles['item-rarity-card-wrapper']}
                  style={{ position: 'absolute' }}
                >
                  {/* <div
                    id={'soldout-label'}
                    className={cn(styles['soldout-label'])}
                  />*/}
                  <ItemRarityCard
                    colorScheme={colorSchemeItemRarity}
                    className={styles['item-rarity-card']}
                    size={'75'}
                    variant={item.rare}
                  />
                </div>
              ) : (
                <ItemRarityLiveFeed
                  colorScheme={colorSchemeLiveFeed}
                  size={'100'}
                  variant={item.rare}
                  className={styles['item-rarity-live-feed']}
                />
              )
            ) : (
              <ItemRarityShopCardBg
                colorScheme={colorSchemeShopCardBg}
                className={styles['item-rarity-shop-card']}
                size={'m'}
                isMM2Variant={game === 'mm2'}
                variant={item.rare}
              />
            )
          }
        />
        <CardItemInfo
          applyHandleClickOnFullCard={applyHandleClickOnFullCard}
          onTitleClick={() =>
            router.push(
              `/shop/${item.type}/${item?.realName}/${selectedCard.id}`,
            )
          }
          info={{
            title: item?.name,
            subtitle: formatAge(selectedCard?.age ?? item.type),
            price: {
              old: selectedCard?.price ?? 0,
              current: selectedCard?.price ?? 0,
            },
          }}
        />

        <div onClick={handleClick} className={styles['properties-slot']}>
          {item.products?.length > 0 && (
            <SelectPetPropertiesFeature
              itemType={item.type}
              products={sortProperties(
                item.products,
                sorting === 'cheaper' ? 'asc' : 'desc',
              )}
              onTabChange={product => {
                setSelectedCard(product);
              }}
              href={`/shop/${item.type}/${item?.realName}/${selectedCard?.id}`}
            />
          )}
        </div>
      </div>

      {variant !== 'tertiary' && variant !== 'quaternary' && (
        <div className={styles['bottom-slot']}>
          {
            <AddToCartFeature
              id={selectedCard.id}
              price={selectedCard.price!}
              prices={selectedCard.prices}
              name={item.realName}
              selected
            />
          }
        </div>
      )}
      {variant === 'tertiary' && (
        <AddToCartFeature
          name={item.realName}
          id={selectedCard.id}
          price={selectedCard.price!}
          prices={selectedCard.prices}
          selected
        />
      )}
    </article>
  );
};
