import { GlobalItem, Product } from '@/shared/types/common';

const filterByProperties = (
  items: GlobalItem[],
  filterArray: Record<string, any> | undefined,
) => {
  const filters = filterArray?.property;

  if (!filters) return items;

  const pumpingFilters = filters.filter(
    (f: string) => f === 'neon' || f === 'mega_neon' || f === 'default',
  );
  const flyableFilter = filters.includes('fly');
  const rideableFilter = filters.includes('ride');

  return items
    .map((item: GlobalItem) => {
      if (item.type !== 'pet') return item;

      const filteredProducts = item.products.filter((product: Product) => {
        const matchesPumping =
          pumpingFilters.length === 0 ||
          pumpingFilters.includes(product.pumping!);
        const matchesFlyable = !flyableFilter || product.flyable === true;
        const matchesRideable = !rideableFilter || product.rideable === true;
        const hasPrices = Number(product.maxCount) > 0;

        return matchesPumping && matchesFlyable && matchesRideable && hasPrices;
      });

      return filteredProducts.length > 0
        ? { ...item, products: filteredProducts }
        : null;
    })
    .filter(Boolean);
};

const filterByRare = (
  items: GlobalItem[],
  filterArray: Record<string, any> | undefined,
) => {
  const filters = filterArray?.rarity;

  if (!filters) return items;

  return items.filter(item => filters.includes(item.rare));
};

const filterByCategories = (
  items: GlobalItem[],
  filterArray: Record<string, any> | undefined,
) => {
  const filters = filterArray?.category;

  if (!filters) return items;

  return items.filter(item =>
    filters?.some((category: string) => category.startsWith(item.type)),
  );
};

const filterBySearch = (
  items: GlobalItem[],
  filterArray: Record<string, any> | undefined,
) => {
  const filters = filterArray?.favoriteSearch;

  if (!filters) return items;

  return items.filter(item =>
    item.name.toLowerCase().includes(filters[0].toLowerCase()),
  );
};

export const filterFavoriteItemsByProperties = (
  items: GlobalItem[],
  filterArray: Record<string, any> | undefined,
) => {
  if (!filterArray) return items;

  const filters = [
    filterByProperties,
    filterByRare,
    filterByCategories,
    filterBySearch,
  ];

  return filters.reduce(
    (acc: GlobalItem[], filter: any) => filter(acc, filterArray),
    items,
  );
};
