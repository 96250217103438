'use client';
import { CardItemEntity } from '@entities/desktop/cards';
import {
  FilterOption,
  ProductsQuery,
  TransportSubType,
} from '@hooks/queries/common.types';
import { useInfiniteProducts } from '@hooks/queries/use-infinite-query-products';
import { useProducts } from '@hooks/queries/use-query-products';
import { ListCardsWidget, ListCardsWidgetVirtualized } from '@widgets/desktop';
import React, { useRef } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import { SeeAllButton } from '@/shared/components/see-all-button';
import { Label } from '@/shared/ui';

export const POPULAR_QUERY_KEY = 'popular-products' as const;

export const getPopularsQueryFilters = (pageParam: number) => {
  const query: ProductsQuery = {
    filter: {
      search: '',
      types: [
        {
          type: 'pet',
          rarity: ['uncommon', 'legendary', 'ultra_rare', 'rare', 'common'],
          levels: ['default', 'mega_neon', 'neon'],
          ages: [
            'newborn',
            'junior',
            'pre_teen',
            'teen',
            'post_teen',
            'reborn',
            'full_grown',
            'twinkle',
            'sparkle',
            'flare',
            'sunshine',
            'luminous',
          ],
        },
        {
          type: 'egg',
          rarity: ['uncommon', 'legendary', 'ultra_rare', 'rare', 'common'],
        },
        {
          type: 'potion',
          rarity: ['uncommon', 'legendary', 'ultra_rare', 'rare', 'common'],
        },
        {
          type: 'transport',
          rarity: ['uncommon', 'legendary', 'ultra_rare', 'rare', 'common'],
          subType: [
            'scooters',
            'skateboards',
            'legendary_gift_vehicles',
            'snowboards',
            'event_vehicles',
            'vehicle_dealership',
            'premium_vehicles',
            'star_rewards_vehicles',
          ],
        },
      ],
      onlyHotSales: false,
      onlyNew: true,
      onlyExclusive: false,
      onlyDiscount: false,
    },
    order: {
      by: 'price',
      direction: 'asc',
    },
    page: 0,
    limit: 16,
  };

  return query;
};

//const defaultRarity =['uncommon', 'legendary', 'ultra_rare', 'rare', 'common'];

export const getShopItemsQueryFilters = (
  pageParam: number,
  filters?: Map<any, any>,
  searchKey: string = 'search',
) => {
  const petsFilters: FilterOption = {
    type: 'pet',
    ...(filters?.has('rarity') && { rarity: filters.get('rarity') }),
    ...(filters?.has('levels') && { levels: filters.get('levels') }),
    ...(filters?.has('age') && {
      ages: filters.get('age'),
    }),
    ...(filters?.has('property') && {
      properties: {
        ...(filters.get('property').includes('fly') && { flyable: true }),
        ...(filters.get('property').includes('ride') && { rideable: true }),
      },
    }),
  };

  const eggsFilters: FilterOption = {
    type: 'egg',
    ...(filters?.has('rarity') && { rarity: filters.get('rarity') }),
  };
  const potionFilters: FilterOption = {
    type: 'potion',
    ...(filters?.has('rarity') && { rarity: filters.get('rarity') }),
  };

  const transportSubTypes: TransportSubType[] = [
    'scooters',
    'skateboards',
    'legendary_gift_vehicles',
    'snowboards',
    'event_vehicles',
    'vehicle_dealership',
    'premium_vehicles',
    'star_rewards_vehicles',
  ];

  const transportFilters: FilterOption = {
    type: 'transport',
    ...(filters?.has('rarity') && { rarity: filters.get('rarity') }),
  };

  const categoryMap = {
    pets: [petsFilters],
    potions: [potionFilters],
    eggs: [eggsFilters],
    transport: [transportFilters],
  };

  const defaultCategories = [
    petsFilters,
    eggsFilters,
    potionFilters,
    transportFilters,
  ];

  const categoryGroups = filters?.has('category')
    ? filters?.get('category')
    : [];

  const selectedGroups: FilterOption[] = categoryGroups.map((group: string) => {
    if (group.startsWith('transport:')) {
      const subtype = group.split(':')[1] as TransportSubType;
      if (transportSubTypes.includes(subtype)) {
        return {
          ...transportFilters,
          subType: [subtype],
        };
      }

      return transportFilters;
    }
    return categoryMap[group as keyof typeof categoryMap] ?? [];
  });

  let selectedTypes =
    selectedGroups.flat().length === 0
      ? defaultCategories
      : selectedGroups.flat();

  const priceFilter = {
    // Не обязательно
    min: filters?.has('priceFrom') ? filters?.get('priceFrom')[0] : '0',
    // Не обязательно
    max: filters?.has('priceTo') ? filters?.get('priceTo')[0] : '10000',
  };

  const query: ProductsQuery = {
    filter: {
      ...(filters?.has(searchKey) && { search: filters.get(searchKey)[0] }),
      price: priceFilter,
      types: selectedTypes,
      ...(filters?.has('sale') && { onlyHotSales: true }),
      ...(filters?.has('category') &&
        filters?.get('category').includes('new') && { onlyNew: true }),
      ...(filters?.has('category') &&
        filters?.get('category').includes('exclusive') && {
          onlyExclusive: true,
        }),
      ...(filters?.has('category') &&
        filters?.get('category').includes('discount') && {
          onlyDiscount: true,
        }),
    },
    order: {
      by: 'price',
      direction: filters?.has('sort')
        ? filters?.get('sort').includes('expensive')
          ? 'desc'
          : filters?.get('sort').includes('cheaper')
            ? 'asc'
            : 'asc'
        : 'asc',
    },
    page: pageParam,
    limit: 20,
  };

  return query;
};

export const PopularItems = () => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const { isLoading, items, isFetching } = useProducts({
    getQueryFiltersFn: getPopularsQueryFilters,
    uniqueQueryKey: POPULAR_QUERY_KEY,
  });

  const isLargeScreen = useMediaQuery('(min-width: 1920px)');
  const isMediumScreen = useMediaQuery('(min-width: 1440px)');
  const isSmallScreen = useMediaQuery('(min-width: 1024px)');

  const slicesItems = isLargeScreen
    ? items
    : isMediumScreen
      ? items.slice(0, 14)
      : items.slice(0, 12);

  return (
    <ListCardsWidget
      cardsVariant={'primary'}
      topSlot={
        <>
          <Label labelVariant={'yellow'} icon={'graph-up'} variant={'primary'}>
            Popular Items
          </Label>{' '}
          <SeeAllButton href={'/shop?sort=popular'} />
        </>
      }
      isLoading={isFetching}
      bottomSlot={
        <>
          {slicesItems.map((item, index) => (
            <CardItemEntity
              applyHandleClickOnFullCard
              isMM2Variant={false}
              variant={'primary'}
              key={`card-${index}-${item.realName}-${item?.name}`}
              item={item}
            />
          ))}
          {!isFetching && <div ref={loadMoreRef}></div>}
        </>
      }
    />
  );
};

/*<ListCardsWidgetVirtualized

      cardsVariant={'primary'}
      topSlot={
        <>
          <Label
            labelVariant={'yellow'}
            icon={'graph-up'}
            variant={'primary'}
          >
          Popular Items
          </Label>{' '}
          <SeeAllButton href={'/shop?sort=popular'} />
        </>
      }
      isLoading={isLoading}
      isFetching={isFetching}
      items={items}
      loadMore={() => fetchNextPage()}
      itemContent={(index, item) => (
        <CardItemEntity
          key={`${item.id}-${index}`}
          applyHandleClickOnFullCard
          isMM2Variant={false}
          variant={'primary'}
          item={item}
        />
      )}
    />*/
