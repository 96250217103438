import cn from 'clsx';
import { motion } from 'framer-motion';
import React, { SyntheticEvent, useState } from 'react';

import { Illustration } from '@/shared/ui';

import styles from './button-favorite.module.scss';
import { ButtonFavoriteProps } from './types';
/*
const iconSizes = {
  l: '24',
  m: '18',
};
*/

export const ButtonFavorite: React.FC<ButtonFavoriteProps> = ({
  isLiked,
  isMobile,
  onPress,
  variant,
}) => {
  const [hovered, setHovered] = useState(false);

  const containerClasses = cn(styles.container, styles[`container-${variant}`]);

  const handleClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    onPress();
  };

  return (
    <motion.button
      whileTap={{ scale: 0.8 }}
      className={containerClasses}
      onClick={handleClick}
      onMouseEnter={() => setHovered(!isMobile)}
      onMouseLeave={() => setHovered(false)}
    >
      <Illustration
        spanTagClassName={cn(styles['btn'], {
          [styles['btn-hovered']]: hovered,
          [styles['btn-active']]: isLiked,
        })}
        name={
          hovered || isLiked
            ? 'button-favorite-filled'
            : 'button-favorite-outline'
        }
        spriteName={'icons'}
      />
    </motion.button>
  );
};
