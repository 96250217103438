import cn from 'clsx';

import { Item } from '@/shared/ui';
import { ItemRarityLiveFeed } from '@/shared/ui';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-live-feed/item-rarity-live-feed';

import styles from './card-live-feed.module.scss';
import {
  CardLiveFeedEntityItemMobileProps,
  CardLiveFeedMobileProps,
} from './card-live-feed.types';

export const CardLiveFeedEntityMobile = ({
  children,
  className,
  item,
  topSlot = <CardLiveFeedEntityMobile.Item item={item} />,
  handleInfoClick,
  ...props
}: CardLiveFeedMobileProps) => {
  const cardLiveFeedClasses = cn(styles['card-live-feed'], className);

  return (
    <article
      onClick={handleInfoClick}
      className={cardLiveFeedClasses}
      {...props}
    >
      <div className={cn(styles['card-live-feed--content'])}>
        <div className={cn(styles['card-live-feed--content__item'])}>
          {topSlot}
        </div>
        {/* <button onClick={handleInfoClick} className={styles['btn-info']}>
          <Illustration
            spanTagClassName={styles['icon-info']}
            spriteName={'icons'}
            style={{ color: 'var(--global-grey700)' }}
            name={'info-circle'} />
        </button>*/}
      </div>
    </article>
  );
};

// eslint-disable-next-line react/display-name
CardLiveFeedEntityMobile.Item = ({
  item,
}: CardLiveFeedEntityItemMobileProps) => {
  const className = cn(
    styles['item-rarity'],
    styles[`item-rarity-${item.game === 'mm2' ? 'mm2' : 'adopt'}`],
  );

  const colorScheme = item.game === 'mm2' ? colorsMM2Variant : colorsByVariant;

  return (
    <>
      <ItemRarityLiveFeed
        colorScheme={colorScheme}
        size={'58'}
        className={className}
        variant={item.rare}
        isMM2Variant={item.game === 'mm2'}
      />
      <Item className={styles['item']} size={'96'} src={item.img} />
    </>
  );
};
