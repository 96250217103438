'use client';
import { useQueryString } from '@hooks/client';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import React, { memo } from 'react';

import { DropListSort } from '@/features/drop-list-sort';
import { Illustration } from '@/shared/ui';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './sorting.module.scss';

const MemoizedTypography = memo(Typography);
const MemoizedIllustration = memo(Illustration);
const MemoizedDropListSort = memo(DropListSort);

export const ShopSorting = observer(() => {
  const shop = useStore()?.shop;
  const { createQueryString } = useQueryString();

  return (
    <div className={styles['sort-wrapper']}>
      <div className={styles['sort_container']}>
        <MemoizedTypography className={styles['sort-title']} weight="semi-bold">
          Sorting:
        </MemoizedTypography>
        <MemoizedDropListSort
          handleSelectSortType={item => {
            const newUrl = createQueryString({
              pathname: '/shop',
              name: 'sort',
              value: item.filter,
              asMultipleParams: false,
            });
            window.history.pushState({}, '', newUrl);
          }}
        />
      </div>
    </div>
  );
});
