'use client';
import { InputStepperEntity } from '@entities/desktop/input-stepper';
import { useCart } from '@hooks/queries/use-query-cart';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';

import { ButtonStepper } from '@/shared/ui/desktop/button-stepper';
import { InputStepper } from '@/shared/ui/desktop/input-stepper';
import { useStore } from '@/store/context';

import styles from './adjust-item-quantity.module.scss';

type AdjustItemQuantityProps = {
  id: number;
  price: number;
  prices: number[];
  selected: boolean;
  initialCount?: number;
  minQuantity?: number;
  maxQuantity?: number;
  realName: string;
  className?: string;
};

export const AdjustItemQuantityFeature = observer(
  ({
    id,
    selected,
    prices,
    price,
    realName,
    initialCount = 1,
    minQuantity = 1,
    maxQuantity = 1,
    className,
  }: AdjustItemQuantityProps) => {
    const [count, setCount] = useState(initialCount);
    const [maxCount, setMaxCount] = useState(maxQuantity);
    const cartStore = useStore().cart;
    const { addToCart } = useCart();

    const addItemRequest = useMemo(
      () => async (quantity: number) => {
        const updatedItem = await addToCart({
          id: id,
          count: quantity,
          prices: prices!,
          price: price!,
          selected: selected,
        });
        if (updatedItem!.count < quantity) {
          cartStore.setNotAvailable(realName);
          setMaxCount(updatedItem!.count);
          setCount(updatedItem?.count ?? 0);
        }
      },
      [],
    );

    useEffect(() => {
      setCount(initialCount);
    }, [id]);

    const debouncedAddItem = useDebounceCallback(addItemRequest, 1000);

    const handleDecrease = (e: MouseEvent<HTMLButtonElement>) => {
      if (count > minQuantity) {
        e.stopPropagation();
        const newCount = count - 1;
        debouncedAddItem(newCount);
        setCount(newCount);
      }
    };

    const handleIncrease = (e: MouseEvent<HTMLButtonElement>) => {
      if (count < maxCount) {
        e.stopPropagation();
        const newCount = count + 1;
        debouncedAddItem(newCount);
        setCount(newCount);
      }
    };

    return (
      <InputStepperEntity
        className={cn(styles['container'], className)}
        leftSlot={
          <ButtonStepper
            stepperType={'minus'}
            disabled={Number(count) <= minQuantity}
            onPress={handleDecrease}
          />
        }
        middleSlot={
          <InputStepper
            value={count}
            setValue={value => {
              setCount(value);
            }}
          />
        }
        rightSlot={
          <ButtonStepper
            stepperType={'plus'}
            disabled={Number(count) >= maxCount}
            onPress={handleIncrease}
          />
        }
      />
    );
  },
);
