'use client';
import { CardPriceItemPageEntity } from '@entities/desktop/cards';
import dynamic from 'next/dynamic';

const LineChartComponent = dynamic(
  () =>
    import('../../../entities/desktop/diagram').then(comp => ({
      default: comp.LineChart,
    })),
  {
    ssr: false,
  },
);

import { useGetProductsRealName } from '@api-product/🧸-товары/🧸-товары';
import { AdjustItemQuantityFeature } from '@features/adjust-item-quantity';
import { AddToCartPriceCardFeature } from '@features/desktop/pet/price-card/add-to-cart/ui';
import { CheckoutPriceCardFeature } from '@features/desktop/pet/price-card/checkout/ui';
import { FastBuyPriceCardFeature } from '@features/desktop/pet/price-card/fast-buy/ui';
import { useCartItemsInfo } from '@hooks/queries/use-cart-items-info';
import { useCart } from '@hooks/queries/use-query-cart';
import {
  PriceCardWidgetCheckoutProps,
  PriceCardWidgetPriceInfoProps,
} from '@widgets/desktop/price-card/types';
import { observer } from 'mobx-react-lite';
import { notFound, usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { useIsClient } from 'usehooks-ts';

import { GlobalItemGroups, Product } from '@/shared/types/common';
import { Divider, Skeleton } from '@/shared/ui';
import { Plate } from '@/shared/ui/desktop/plate';
import { useStore } from '@/store/context';

import styles from './price-card.module.scss';

export const PriceCardWidget = ({
  pet,
  productId,
}: {
  pet: string;
  productId: number;
}) => {
  const isClient = useIsClient();
  const [item, setItem] = useState<GlobalItemGroups | undefined>(undefined);
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const pathname = usePathname();
  const [localProductId, setLocalProductId] = useState<number>(productId);
  const [dataProcessed, setDataProcessed] = useState<boolean>(false);

  const {
    data: productData,
    isLoading,
    isFetching,
    error,
    isSuccess,
  } = useGetProductsRealName(pet);

  useEffect(() => {
    if (isSuccess && productData?.data) {
      try {
        const itemFromApi = productData.data as unknown as GlobalItemGroups;
        setItem(itemFromApi);

        if (
          itemFromApi &&
          itemFromApi.products &&
          Array.isArray(itemFromApi.products)
        ) {
          const foundProduct = itemFromApi.products.find(
            p => Number(p.id) === Number(localProductId),
          );
          setProduct(foundProduct);
          setDataProcessed(true);
        } else {
          setProduct(undefined);
          setDataProcessed(true);
        }
      } catch (e) {
        setItem(undefined);
        setProduct(undefined);
        setDataProcessed(true);
      }
    }
  }, [productData, isSuccess, localProductId]);

  useEffect(() => {
    const segments = pathname.split('/');

    const productId = segments[segments.length - 1];

    setLocalProductId(Number(productId));
  }, [pathname]);

  if (isLoading) {
    return <Skeleton className={styles.container} />;
  }

  if ((!item || !product) && isSuccess && !isFetching && dataProcessed) {
    return notFound();
  }

  if (product) {
    return (
      <article className={styles.container}>
        <div className={styles['top']}>
          <PriceCardWidget.PriceInfo price={product?.price ?? 0} />
          <Divider direction="horizontal" />
          <div className={styles.buttons_container}>
            <PriceCardWidget.Checkout product={product} pet={pet} />
            <FastBuyPriceCardFeature product={product} />
          </div>
        </div>
        {/*<LineChartComponent
        labels={[
          'Feb 05, 2023',
          'Feb 06, 2023',
          'Feb 07, 2023',
          'Feb 08, 2023',
          'Feb 09, 2023',
          'Feb 10, 2023',
          'Feb 11, 2023',
          'Feb 11, 2023',
        ]}
        data={[0.1, 0.2, 0.3, 0.9, 0.5, 0.3, 0.3, 0.2]}
      />*/}
      </article>
    );
  }

  return null;
};

PriceCardWidget.Checkout = observer(
  ({ product, pet }: PriceCardWidgetCheckoutProps) => {
    const { items } = useCart();

    const cartItem = items?.find(item => item.id === product.id);

    const isClient = useIsClient();

    if (!isClient) {
      return <Plate />;
    }

    return !cartItem ? (
      <AddToCartPriceCardFeature
        disabled={!product.maxCount || !product.price}
        itemId={product.id}
        price={product.price!}
        prices={product.prices!}
      />
    ) : (
      <div className={styles.top_button_container}>
        <AdjustItemQuantityFeature
          id={product.id}
          prices={product.prices!}
          price={product.price!}
          selected={cartItem.selected}
          minQuantity={1}
          maxQuantity={10}
          realName={pet}
          className={styles['quantity-container']}
        />
        <Divider direction="vertical" />
        <div className={styles.stepper_container}>
          <CheckoutPriceCardFeature />
        </div>
      </div>
    );
  },
);

PriceCardWidget.PriceInfo = observer(
  ({ price }: PriceCardWidgetPriceInfoProps) => {
    return (
      <CardPriceItemPageEntity
        text={price === 0 ? 'Sold out' : 'Best price'}
        priceInfo={{ old: price, current: price }}
      />
    );
  },
);
