import cn from 'clsx';
import React from 'react';

import { WithCurrency } from '@/shared/hocs';
import { Item, ItemRarityLiveFeed, TagInfo, Typography } from '@/shared/ui';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant as colorsByVariantLiveFeed,
  colorsMM2Variant as colorsMM2VariantLiveFeed,
} from '@/shared/ui/item-rarity-live-feed/item-rarity-live-feed';

import styles from './card-offer.module.scss';
import {
  CardOfferEntityItemInfoProps,
  CardOfferEntityItemProps,
  CardOfferEntityProps,
} from './card-offer.types';

export const CardOfferEntity = ({
  className,
  topSlot,
  bottomSlot,
  ...props
}: CardOfferEntityProps) => {
  return (
    <article className={cn(styles['card-offer'], className)} {...props}>
      {topSlot}
      {bottomSlot}
    </article>
  );
};

export const CardOfferEntityItem = ({ item }: CardOfferEntityItemProps) => {
  const colorSchemeLiveFeed =
    item.game === 'mm2' ? colorsMM2VariantLiveFeed : colorsByVariantLiveFeed;

  return (
    <div className={styles['item']}>
      <ItemRarityLiveFeed
        colorScheme={colorSchemeLiveFeed}
        size={'100'}
        variant={item.rare}
        isMM2Variant={item.game === 'mm2'}
        className={styles['background']}
      />
      <Item className={styles['item-image']} size={'68'} src={item.imageUri} />
      {/*{Array.isArray(tagsInfo) && tagsInfo.length > 0 && (*/}
      {/*  <div*/}
      {/*    id={'tags-info-container'}*/}
      {/*    className={cn(styles['tags-info-container'])}*/}
      {/*  >*/}
      {/*    {tagsInfo.map((tag, idx) => {*/}
      {/*      return (*/}
      {/*        <TagInfo*/}
      {/*          isMM2Variant={isMM2Variant}*/}
      {/*          key={`tag-${idx}-info`}*/}
      {/*          id={'tag-info'}*/}
      {/*          className={cn(styles['tag-info'])}*/}
      {/*          variant={tag.variant}*/}
      {/*        >*/}
      {/*          {tag.text}*/}
      {/*        </TagInfo>*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};
export const CardOfferEntityItemInfo = ({
  title,
  price,
}: CardOfferEntityItemInfoProps) => {
  return (
    <div className={styles['item-info']}>
      <div className={styles['prices']}>
        <Typography className={styles['current']}>
          {' '}
          <WithCurrency>{price.current}</WithCurrency>
        </Typography>
        {price.old && (
          <Typography className={styles['old']}>
            <WithCurrency>{price.old}</WithCurrency>
          </Typography>
        )}
      </div>
      <Typography className={styles['title']}>{title}</Typography>
    </div>
  );
};
