import cn from 'clsx';
import React from 'react';

import { WithCurrency } from '@/shared/hocs';
import { TagCategory, Typography } from '@/shared/ui';

import styles from './card-order.module.scss';
import { CheckoutInfoType } from './card-order.types';

export const CardOrder = ({
  info,
  onClick,
}: {
  info: CheckoutInfoType;
  onClick?: () => void;
}) => {
  const titleClasses = cn(styles['title'], {
    [styles['title-action']]: info.type === 'action',
  });

  const priceColor = !info.oldValue
    ? 'neutral'
    : info.value > info.oldValue
      ? 'negative'
      : 'positive';

  return (
    <div className={styles['item']}>
      <div className={styles['title-container']}>
        <Typography
          className={styles['decoration']}
          size={'base'}
          weight={'medium'}
        >
          •
        </Typography>
        <Typography
          className={titleClasses}
          weight={'medium'}
          onClick={onClick}
        >
          {info.title}
        </Typography>

        {info.rideable && <TagCategory size={18} variant={'ride'} />}
        {info.flyable && <TagCategory size={18} variant={'fly'} />}
      </div>
      {info.type !== 'action' && (
        <div className={styles['value-container']}>
          <Typography className={cn(styles['value'], styles['old-value'])}>
            {info.type === 'item' && !!info.oldValue && (
              <WithCurrency>{Number(info.oldValue).toFixed(2)}</WithCurrency>
            )}
          </Typography>
          <Typography
            className={cn(
              styles['value'],
              styles[`value-${info.type !== 'item' ? 'positive' : priceColor}`],
            )}
            weight={'semi-bold'}
          >
            {info.type === 'item' ? '+' : '-'}{' '}
            <WithCurrency>
              {info.value < 0
                ? (info.value * -1).toFixed(2)
                : Number(info.value).toFixed(2)}
            </WithCurrency>
          </Typography>
        </div>
      )}
    </div>
  );
};
