'use client';
import { AddToCartOfferProps } from '@features/desktop/pet/offer/add-to-cart/ui/types';
import { useCart } from '@hooks/queries/use-query-cart';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { MouseEvent } from 'react';

import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';

import styles from './add-to-cart.module.scss';
export const AddToCartOfferFeature = observer(
  ({ item }: AddToCartOfferProps) => {
    const { addToCart, items } = useCart();

    const handleAddToCart = (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
      e?.stopPropagation();
      item &&
        addToCart({
          id: item.id,
          count: 1,
          prices: item.prices!,
          selected: true,
          price: item.price!,
        });
    };

    return (
      <Button
        as={motion.button}
        whileTap={{ scale: 0.95 }}
        disabled={items.some(cartItem => cartItem.id == item?.id)}
        iconLeft={
          <Illustration
            style={{ color: '#A58DDD' }}
            name={'cart-plus'}
            id={'btn-icon'}
            spriteName={'icons'}
          />
        }
        variant={'secondary'}
        className={styles['action-btn']}
        onClick={handleAddToCart}
        text={'Add to cart'}
      />
    );
  },
);
