'use client';
import { usePostProducts } from '@api-product/🧸-товары/🧸-товары';
import { ProductsQuery } from '@hooks/queries/common.types';
import { useInfiniteQuery } from '@tanstack/react-query';
//import { useQueryClient } from '@tanstack/react-query';
//import { resetInfiniteQueryPagination } from '@utils/reset-infinite-query-pagination';
import { RefObject, useMemo } from 'react';

const ITEMS_PER_PAGE = 16;

export const useInfiniteProducts = ({
  loadMoreRef,
  getQueryFiltersFn,
  uniqueQueryKey,
  filters,
}: {
  loadMoreRef: RefObject<HTMLDivElement>;
  getQueryFiltersFn: (
    pageParam: number,
    filters?: Map<any, any>,
  ) => ProductsQuery;
  uniqueQueryKey: string;
  filters?: Map<any, any>;
}) => {
  //const queryClient = useQueryClient();

  const queryKey = useMemo(
    () => ['products', uniqueQueryKey, Array.from(filters || [])],
    [uniqueQueryKey, filters],
  );

  const { mutateAsync } = usePostProducts();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isPending,
    isSuccess,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 0 }) => {
      return mutateAsync({
        data: getQueryFiltersFn(pageParam, filters),
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.data) return undefined;

      const totalItems = lastPage.data.count;
      const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
      const fetchedItems = allPages.reduce(
        (acc, page) => acc + (page.data?.rows?.length || 0),
        0,
      );

      // If we've fetched all items, stop
      if (fetchedItems >= totalItems) {
        return undefined;
      }

      // Next page number
      const nextPage = allPages.length;

      return nextPage < totalPages ? nextPage : undefined;
    },
    initialPageParam: 0,
    refetchOnMount: true,
    //refetchOnMount: 'always',
    //refetchInterval: 60000,
    refetchOnWindowFocus: false,
    gcTime: 0, // Set to 0 to prevent caching
  });

  const items = data?.pages.flatMap(page => page.data.rows) ?? [];
  const isInitialLoading = isLoading || (isFetching && !isFetchingNextPage);

  return {
    items,
    isLoading: isLoading || isPending,
    isFetching,
    isFetchingNextPage,
    isInitialLoading,
    fetchNextPage,
    hasNextPage,
    isSuccess,
  };
};
