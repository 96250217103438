import cn from 'clsx';

import { Illustration, TagCategory, TagRarity } from '@/shared/ui';
import { ChipProps } from '@/shared/ui/desktop/chip/chip.types';
import { Typography } from '@/shared/ui/typography';

import styles from './chip.module.scss';
export const Chip = ({
  variant,
  tagsCategory,
  tagsRarity,
  className,
  label,
  customIcon,
  handleClose,
  disabled,
  selectable,
  isSelected,
  onClick,
  ...props
}: ChipProps) => {
  const chipClasses = cn(
    styles['chip'],
    { [styles['chip--selected']]: isSelected },
    styles[`variant--${variant}`],
    { [styles['disabled']]: disabled },
    className,
  );

  return (
    <div onClick={onClick} className={chipClasses} {...props}>
      {Array?.isArray(tagsRarity) &&
        tagsRarity.length > 0 &&
        tagsRarity?.map((tag, idx) => {
          return <TagRarity key={`chip-${tag}-${idx}`} variant={tag} />;
        })}
      {Array?.isArray(tagsCategory) &&
        tagsCategory.length > 0 &&
        tagsCategory?.map((tag, idx) => {
          return <TagCategory key={`chip-${tag}-${idx}`} variant={tag} />;
        })}
      {customIcon}
      <Typography>{label}</Typography>
      {/*{(!selectable || isSelected) && (*/}
      {/*  <Illustration*/}
      {/*    id={'chip-close-btn-icon'}*/}
      {/*    name={'close-circle-header'}*/}
      {/*    spriteName={'icons'}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};
