'use client';
import { usePostProducts } from '@api-product/🧸-товары/🧸-товары';
import { ProductsQuery } from '@hooks/queries/common.types';
import { InitialPageParam, useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useRef } from 'react';

export const useProducts = ({
  getQueryFiltersFn,
  uniqueQueryKey,
}: {
  getQueryFiltersFn: (pageParam: number) => ProductsQuery;
  uniqueQueryKey: string;
}) => {
  //const queryClient = useQueryClient();
  //const isFirstMount = useRef(true);

  const queryKey = useMemo(
    () => ['products', uniqueQueryKey],
    [uniqueQueryKey],
  );

  const { mutateAsync } = usePostProducts();

  const { data, isLoading, isFetching, isPending } = useQuery({
    queryKey,
    queryFn: ({ pageParam }) => {
      return mutateAsync({
        data: getQueryFiltersFn(pageParam as number),
      });
    },
    //refetchOnMount: 'always',
    //refetchInterval: 60000 * 5,
    refetchOnWindowFocus: false,
    gcTime: 0, // Set to 0 to prevent caching
  });

  /*
  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
      queryClient.removeQueries({ queryKey });
      queryClient.prefetchQuery({ queryKey });
    }
  }, [queryClient, queryKey]);*/

  //console.log('data', data);
  const items: any[] = data?.data?.rows ?? [];
  const totalCount = data?.data?.count ?? 0;

  return {
    items,
    totalCount,
    isLoading: isLoading,
    isFetching,
  };
};
