import { GlobalItem, GlobalItemGroups } from '@/shared/types/common';

export const mockedMM2Data: GlobalItem[] = [
  {
    rare: 'uncommon',
    type: 'knife',
    realName: 'zombie_knife',
    game: 'mm2',
    variant: null,
    name: 'Zombie knife',
    subtype: null,
    img: 'https://bloxway.ams3.cdn.digitaloceanspaces.com/img/knife.webp',
    products: [
      {
        id: 111111,
        price: 3.22,
        prices: [3.22, 3.22, 3.22],
        maxCount: 3,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
      },
    ],
  },
  {
    rare: 'godly',
    type: 'knife',
    game: 'mm2',
    variant: null,
    realName: 'elderwood_blade_knife',
    name: 'Elderwood Blade Knife',
    subtype: 'scooters',
    img: 'https://bloxway.ams3.cdn.digitaloceanspaces.com/img/knife.webp',
    products: [
      {
        id: 111112,
        price: 43.32,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
        prices: [43.32, 43.32, 43.32, 43.32],
        maxCount: 4,
      },
    ],
  },
  {
    rare: 'common',
    type: 'knife',
    game: 'mm2',
    variant: null,
    realName: 'chroma_browser_knife',
    name: 'Chroma Browser Knife',
    subtype: null,
    img: 'https://bloxway.ams3.cdn.digitaloceanspaces.com/img/chroma-knife.webp',
    products: [
      {
        id: 111113,
        price: 144,
        prices: [144, 144, 144, 144],
        maxCount: 4,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
      },
    ],
  },
  {
    rare: 'uncommon',
    type: 'knife',
    game: 'mm2',
    variant: null,
    realName: 'chroma_zombie_knife',
    name: 'Chroma Zombie Knife',
    subtype: null,
    img: 'https://bloxway.ams3.cdn.digitaloceanspaces.com/img/chroma-knife.webp',
    products: [
      {
        id: 111114,
        price: 33,
        prices: [33, 33, 33, 33, 33, 33, 33, 33, 33],
        maxCount: 9,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
      },
    ],
  },
  {
    rare: 'legendary',
    type: 'knife',
    game: 'mm2',
    variant: null,
    realName: 'chroma_elderwood_blade_knife',
    name: 'Chroma Elderwood Blade Knife',
    subtype: null,
    img: 'https://bloxway.ams3.cdn.digitaloceanspaces.com/img/chroma-knife.webp',
    products: [
      {
        id: 111115,
        price: 144,
        prices: [144, 144, 144, 144],
        maxCount: 4,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
      },
    ],
  },
  {
    rare: 'ancient',
    type: 'knife',
    game: 'mm2',
    variant: null,
    realName: 'basic_egg_2022_poodle',
    name: 'Zombie Chroma Knife',
    subtype: null,
    img: 'https://bloxway.ams3.cdn.digitaloceanspaces.com/img/chroma-knife.webp',
    products: [
      {
        id: 111116,
        price: 33,
        prices: [33, 33, 33, 33, 33, 33, 33, 33, 33],
        maxCount: 9,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
      },
    ],
  },
];

export const mockedMM2ItemsGroups: GlobalItemGroups[] = [
  {
    rare: 'uncommon',
    type: 'knife',
    realName: 'zombie_knife',
    game: 'mm2',
    name: 'Zombie knife',
    subtype: null,
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me! that was released on June 16, 2022. It can be obtained through hatching a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that its respective event has ended, it can only be obtained through trading.',
    imageUri: 'https://bloxway.ams3.cdn.digitaloceanspaces.com/img/knife.webp',
    products: [
      {
        id: 111111,
        price: 3.22,
        prices: [3.22, 3.22, 3.22],
        maxCount: 3,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
      },
    ],
  },
  {
    rare: 'godly',
    type: 'knife',
    game: 'mm2',
    realName: 'elderwood_blade_knife',
    name: 'Elderwood Blade Knife',
    subtype: 'scooters',
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me! that was released on June 16, 2022. It can be obtained through hatching a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that its respective event has ended, it can only be obtained through trading.',

    imageUri: 'https://bloxway.ams3.cdn.digitaloceanspaces.com/img/knife.webp',
    products: [
      {
        id: 111112,
        price: 43.32,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
        prices: [43.32, 43.32, 43.32, 43.32],
        maxCount: 4,
      },
    ],
  },
  {
    rare: 'common',
    type: 'knife',
    game: 'mm2',
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me! that was released on June 16, 2022. It can be obtained through hatching a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that its respective event has ended, it can only be obtained through trading.',

    realName: 'chroma_browser_knife',
    name: 'Chroma Browser Knife',
    subtype: null,
    imageUri:
      'https://bloxway.ams3.cdn.digitaloceanspaces.com/img/chroma-knife.webp',
    products: [
      {
        id: 111113,
        price: 144,
        prices: [144, 144, 144, 144],
        maxCount: 4,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
      },
    ],
  },
  {
    rare: 'uncommon',
    type: 'knife',
    game: 'mm2',
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me! that was released on June 16, 2022. It can be obtained through hatching a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that its respective event has ended, it can only be obtained through trading.',

    realName: 'chroma_zombie_knife',
    name: 'Chroma Zombie Knife',
    subtype: null,
    imageUri:
      'https://bloxway.ams3.cdn.digitaloceanspaces.com/img/chroma-knife.webp',
    products: [
      {
        id: 111114,
        price: 33,
        prices: [33, 33, 33, 33, 33, 33, 33, 33, 33],
        maxCount: 9,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
      },
    ],
  },
  {
    rare: 'legendary',
    type: 'knife',
    game: 'mm2',
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me! that was released on June 16, 2022. It can be obtained through hatching a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that its respective event has ended, it can only be obtained through trading.',

    realName: 'chroma_elderwood_blade_knife',
    name: 'Chroma Elderwood Blade Knife',
    subtype: null,
    imageUri:
      'https://bloxway.ams3.cdn.digitaloceanspaces.com/img/chroma-knife.webp',
    products: [
      {
        id: 111115,
        price: 144,
        prices: [144, 144, 144, 144],
        maxCount: 4,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
      },
    ],
  },
  {
    rare: 'ancient',
    type: 'knife',
    game: 'mm2',
    description:
      'The Carpet Zombie Ponycycle is a limited legendary pet in Adopt Me! that was released on June 16, 2022. It can be obtained through hatching a Zodiac Minion Egg; Gru, an NPC, tasked players to hatch this egg. Now that its respective event has ended, it can only be obtained through trading.',
    realName: 'basic_egg_2022_poodle',
    name: 'Zombie Chroma Knife',
    subtype: null,
    imageUri:
      'https://bloxway.ams3.cdn.digitaloceanspaces.com/img/chroma-knife.webp',
    products: [
      {
        id: 111116,
        price: 33,
        prices: [33, 33, 33, 33, 33, 33, 33, 33, 33],
        maxCount: 9,
        age: null,
        flyable: null,
        rideable: null,
        pumping: null,
      },
    ],
  },
];
