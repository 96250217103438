'use client';
import { CardItemOfferProps } from '@entities/desktop/cards/card-item-offer/ui/card-item-offer.types';
import { formatAge } from '@utils/helpers';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { WithCurrency } from '@/shared/hocs';
import { Item, Skeleton } from '@/shared/ui';
import { ItemRarityCard } from '@/shared/ui';
import { itemVariantsMM2 } from '@/shared/ui/item/item.types';
import {
  colorsByVariant,
  colorsMM2Variant,
} from '@/shared/ui/item-rarity-card/item-rarity-card';
import { TagCategory } from '@/shared/ui/tag-category';
import { TagRarity } from '@/shared/ui/tag-rarity';
import { Typography } from '@/shared/ui/typography';
import { useStore } from '@/store/context';

import styles from './card-item-offer.module.scss';
export const CardItemOfferEntity = observer(
  ({
    children,
    className,
    bottomSlot,
    flyable,
    rideable,
    price,
    age,
    type,
    imageUri,
    rare,
    game,
    ...props
  }: CardItemOfferProps) => {
    const cardLiveFeedClasses = cn(styles['card-item-offer'], className);
    const isClient = useIsClient();
    const isHydrated = useStore().app.isHydrated && isClient;

    const colorScheme = game === 'mm2' ? colorsMM2Variant : colorsByVariant;

    return (
      <article className={cardLiveFeedClasses} {...props}>
        <div className={styles['item']}>
          <ItemRarityCard
            colorScheme={colorScheme}
            className={styles['background']}
            style={{ position: 'absolute', zIndex: 0 }}
            size={'66'}
            variant={rare}
          />
          <Item
            className={styles['item--variant']}
            style={{
              zIndex: 1,
              pointerEvents: 'none',
              transition: 'all 0.15s ease-in-out',
            }}
            size={'48'}
            src={imageUri}
          />
        </div>
        <div className={styles['content']}>
          <div className={styles['price-info-container']}>
            <div className={styles['info-wrapper']}>
              <div className={styles['price-info']}>
                {isHydrated ? (
                  <>
                    <Typography className={styles['current']}>
                      <WithCurrency>{price}</WithCurrency>
                    </Typography>

                    {price && (
                      <Typography
                        className={styles['old']}
                        decoration={'line-through'}
                      >
                        <WithCurrency>{price}</WithCurrency>
                      </Typography>
                    )}
                  </>
                ) : (
                  <Skeleton style={{ width: 60, height: 32 }} />
                )}
              </div>

              <Typography className={styles['age']}>
                {formatAge(age) ?? type}
              </Typography>
            </div>
            <div className={styles.properties}>
              <TagRarity size={18} variant={rare} />
              {flyable && <TagCategory size={18} variant={'fly'} />}
              {rideable && <TagCategory size={18} variant={'ride'} />}
            </div>
          </div>
          {bottomSlot}
        </div>
      </article>
    );
  },
);
